import React, { useState } from "react";
import { Header, SideBar } from ".";
import { Outlet } from "react-router-dom";

const RootLayout = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query: any) => {
    setSearchQuery(query);
  };
  return (
    <div className="root-layout">
      {/* SIDE BAR */}
      <SideBar />

      {/* CONTENT */}
      <div className="root-layout__content">
        <div className="px-4">
          <Header onSearch={handleSearch} />
        </div>

        {/* MAIN CONTENT */}
        <div className="root-layout__container">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default RootLayout;
