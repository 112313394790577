import React from "react";
import { Card, CardBody, CardFooter } from "reactstrap";

const HoverCard = ({ rowData }: any) => {
  return (
    <React.Fragment>
      <Card
        className={`border-none h-[310px] w-[320px] absolute top-5 right-20 z-50`}
      >
        <CardBody className="">
          <div className="flex text-center font-medium flex-col">
            <img
              className="mb-4"
              src={
                rowData?.productImages[0]?.image ||
                "https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg"
              }
              alt=""
            />
            <p>{rowData?.name}</p>
          </div>
        </CardBody>
        <CardFooter className="bg-white text-center font-medium">
          Price: {rowData?.price}
        </CardFooter>
      </Card>
    </React.Fragment>
  );
};

export default HoverCard;
