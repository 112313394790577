import axios from "axios";

// const API_URL =
//   "https://pxn-backend.herokuapp.com/api/v2/user-mgt/dashboard";
const API_URL =
  "https://pxn-api-zzpou.ondigitalocean.app/api/v2/user-mgt/dashboard";

const getAdmin = async (token: any) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data;
};

const adminService = {
  getAdmin,
};

export default adminService;
