import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Spinner } from "reactstrap";
import { BiArrowBack } from "react-icons/bi";
import ReplyChart from "./ReplyChart";
import { FaTelegramPlane } from "react-icons/fa";
import { MdAttachFile } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getTicket, sendMail } from "../../features/tickets/ticketsSlice";
import { toast } from "react-toastify";

const initialState = { message: "" };
const SupportReply = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState(initialState);
  const { ticket, isSuccess, isError } = useAppSelector(
    (state) => state.tickets
  );

  console.log({ ticket });

  const { message } = formData;

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (id) {
      const data = {
        message,
      };

      dispatch(sendMail({ id: parseInt(id, 10), data }));
      setFormData(initialState);
    } else {
      console.error("Invalid ID");
      setFormData(initialState);
    }

    if (isSuccess) {
      toast.success("Mail sent successfuly !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (isError) {
      toast.error("Mail not sent", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    if (id) {
      const storeId = parseInt(id, 10);

      if (!isNaN(storeId)) {
        dispatch(getTicket(storeId));
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Card className="border-none min:h-screen">
        <CardBody>
          <Row className="mb-2">
            <div className="flex flex-col p-2 px-4 border-b border-[#1B1C1E1A] w-4/5">
              <div className="flex items-center">
                <div className="bg-white rounded-full p-2 hover:shadow-lg flex items-center justify-center cursor-pointer">
                  <BiArrowBack />
                </div>

                <div className="ml-5 flex items-center">
                  <img
                    className="w-8 h-8 rounded-full"
                    src={
                      ticket?.ticket?.image ||
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                  />
                  <div className="ml-2 flex flex-col">
                    <p className="font-bold">
                      {" "}
                      {ticket?.ticket?.ticketUser?.fullname ||
                        ticket?.ticket?.ticketUser?.firstname ||
                        ticket?.ticket?.ticketUser?.lastname}
                    </p>
                    <p className="text-[#1B1C1E80] text-xs">#23457654</p>
                  </div>
                </div>
              </div>
            </div>
          </Row>

          <ReplyChart
            name={
              ticket?.ticket?.ticketUser?.fullname ||
              ticket?.ticket?.ticketUser?.firstname ||
              ticket?.ticket?.ticketUser?.lastname
            }
            date={
              ticket?.ticket?.createdAt
                ? new Date(
                    Date.parse(ticket?.ticket?.createdAt)
                  ).toLocaleString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : "Invalid Date"
            }
            complain={ticket?.ticket?.complaint}
          />
        </CardBody>

        <CardBody className="mt-10">
          <div className="flex items-center">
            <div className="border p-2 flex items-center w-full h-14 border-[#1B1C1E0DF] rounded-md">
              <MdAttachFile className="text-[#1B1C1E80] text-lg mr-3" />
              <input
                type="text"
                name="message"
                value={message}
                onChange={onChange}
                placeholder="Type your message..."
                className="w-full h-full border-none outline-none"
              />
            </div>
            <div
              onClick={message.length > 0 ? onSubmit : undefined}
              className={`${
                message.length <= 0 && "opacity-40"
              } w-14 h-14 rounded-full ml-3 cursor-pointer bg-[#199DE81A] flex justify-center items-center text-[#199DE8]`}
            >
              <FaTelegramPlane />
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SupportReply;
