import React, { useState, useRef, useEffect } from "react";
import { SlOptionsVertical } from "react-icons/sl";

const UsersActions = ({ onDelete, onEdit, userState }: any) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropDown(false);
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const show = () => {
    setShowDropDown(!showDropDown);
  };

  return (
    <React.Fragment>
      <div
        onClick={show}
        className="w-fit relative hover:shadow-md p-2 flex items-center justify-center hover:p-1 cursor-pointer hover:bg-white hover:rounded-full transition-all duration-500"
      >
        <div
          ref={dropdownRef}
          className={`absolute top-0 right-5 w-32 rounded-md ${
            showDropDown ? "flex" : "hidden"
          } flex-col action-shadow`}
        >
          <div
            onClick={onEdit}
            className="cursor-pointer  w-full bg-white p-1 flex items-center justify-center text-sm rounded-t-md"
          >
            <div className="w-[50%]">
              <p>Details</p>
            </div>
          </div>
          <div
            onClick={onDelete}
            className=" cursor-pointer w-full bg-red-400 p-1 flex items-center justify-center text-sm rounded-b-md"
          >
            <div className="w-[50%]">
              <p>{userState}</p>
            </div>
          </div>
        </div>
        <SlOptionsVertical className="text-xs cursor-pointer" />
      </div>
    </React.Fragment>
  );
};

export default UsersActions;
