import axios from "axios";

// const API_URL = "https://pxn-backend.herokuapp.com/api/v2/dispatch/company/";
const API_URL =
  "https://pxn-api-zzpou.ondigitalocean.app/api/v2/dispatch/company/";

interface IParams {
  token: string;
  page: number;
  pageSize: number;
}

interface IRiders {
  token: string;
  id: number;
}

interface IBan {
  token: string;
  id: number;
  data: any;
}

// get all logistics companies
const getAllLogistics = async ({ token, page, pageSize }: IParams) => {
  const params = {
    page,
    pageSize,
  };

  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(API_URL + "list/admin", {
      params,
      headers: config.headers,
    });

    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching logistics:", error);
    throw error;
  }
};

// get un-verified companies
const getUnverified = async (token: string) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(API_URL + "pending-verification", config);

    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching unverified:", error);
    throw error;
  }
};

const getCompanyRiders = async ({ token, id }: IRiders) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(`${API_URL}${id}/details`, config);

    console.log(response, "company riders");

    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching company riders:", error);
    throw error;
  }
};

const deactivateCompany = async ({ token, id, data }: IBan) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.put(
      API_URL + `deactivate/${id}`,
      data,
      config
    );
    console.log(response, "deactivated");
    return response;
  } catch (error) {
    console.error("Error deactivating company:", error);
    throw error;
  }
};

const verifyCompany = async ({ token, id }: IRiders) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.post(API_URL + `verify/${id}`, {}, config);
    console.log(id, "omoooo");

    console.log(config, response, "verified");
    return response;
  } catch (error) {
    console.error("Error verifying company:", error);
    throw error;
  }
};

const activateCompany = async ({ token, id }: IRiders) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.put(
      API_URL + `activate/${id}`,
      {},
      {
        headers: config.headers,
      }
    );
    console.log(response, "activated");
    return response;
  } catch (error) {
    console.error("Error activating company:", error);
    throw error;
  }
};

const logisticsService = {
  getAllLogistics,
  getCompanyRiders,
  deactivateCompany,
  activateCompany,
  getUnverified,
  verifyCompany,
};

export default logisticsService;
