import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { RadialBarChart, SalesChart } from "../../components/Charts";
// import { Progress } from "react-sweet-progress";
// import "react-sweet-progress/lib/style.css";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Counter from "../../components/Counter/Counter";
import { getAdmin } from "../../features/admin/adminSlice";
import { getTransAllTime } from "../../features/transactions/transactionsSlice";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const [selectedYear, setSelectedYear] = useState(0);
  const { admin } = useAppSelector((state) => state.admin);
  const { allTimeTrans } = useAppSelector((state) => state.transactions);

  console.log({ allTimeTrans });

  const logisticCompanies = admin?.data?.companies;

  const totalWaletUsers = admin?.data?.wallets;

  const totalStores = admin?.data?.stores;

  const totalMale = admin?.data?.males;
  const totalFemale = admin?.data?.females;

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const year = parseInt(event.target.value);
    setSelectedYear(year);
  };

  const getYearsArray = (startYear: number, endYear: number) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const formatNumber = (value: number) => {
    if (Math.abs(value) >= 1000000) {
      return (value / 1000000).toFixed(2) + "M";
    } else if (Math.abs(value) >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    } else {
      return value.toString();
    }
  };

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Generate an array of years from 2020 to the current year
  const yearsArray = getYearsArray(2020, currentYear);

  useEffect(() => {
    dispatch(getAdmin());
    dispatch(getTransAllTime());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Row className="">
        <Counter counter={totalWaletUsers} md={3} title="Wallet Users" />

        <Counter counter={totalStores} md={3} title="Stores Created" />

        <Counter
          counter={logisticCompanies}
          md={6}
          title="Registered Logistics Companies"
        />
      </Row>

      <Row className="mt-4">
        <Col md={3} className="col-12">
          <Card className="border-none h-100">
            <CardBody className="">
              <CardTitle className="mb-4 font-bold text-xl">
                User's Summary
              </CardTitle>
              <RadialBarChart />

              <div className="flex items-center justify-center mt-4 mb-2">
                <div className="flex flex-row items-center">
                  <div className="bg-[#199DE8] w-5 h-5 rounded-full font-bold text-[10px] text-white flex justify-center items-center">
                    {totalMale}
                  </div>
                  <p className="ml-3">Male</p>
                </div>
                <div className="flex flex-row items-center ml-10">
                  <div className="bg-[#199DE880] w-5 h-5 rounded-full font-bold text-[10px] text-white flex justify-center items-center">
                    {totalFemale}
                  </div>
                  <p className="ml-3">Female</p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={9} className="col-12">
          <Card className="border-none h-100">
            <CardBody>
              <div className="flex justify-between items-center mb-3">
                <p className="font-bold text-xl leading-8">
                  Transactions by Month
                </p>

                <select
                  name=""
                  id=""
                  className="border border-[#1B1C1E0D] rounded-[10px] text-xs text-[#1B1C1E80] p-1 px-2 outline-none"
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  {yearsArray.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <SalesChart year={selectedYear} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={5}>
          <Card className="border-none h-100">
            <CardBody>
              <div className="flex justify-between items-center mb-3">
                <p className="font-bold text-xl leading-8">Statistics</p>

                <select
                  name=""
                  id=""
                  className="border border-[#1B1C1E0D] rounded-[10px] text-xs text-[#1B1C1E80] p-1 px-2 outline-none"
                >
                  <option value="">June, 2023</option>
                  <option value="">June, 2023</option>
                  <option value="">June, 2023</option>
                </select>
              </div>

              <div className="">
                <div className="flex justify-between items-center mb-3">
                  <div className="h-7 w-7 text-[#199DE8] bg-[#199DE81A] rounded-[5] flex justify-center items-center">
                    <BiUpArrowAlt className="text-2xl" />
                  </div>
                  <div className="flex flex-col ml-2 w-40">
                    <p className="text-xs text-[#1B1C1E80] leading-5 font-medium">
                      Total Income
                    </p>
                    <p className="font-bold leading-5">NGN 100,000</p>
                  </div>
                  {/* <Progress
                    percent={69}
                    color="#FF0000"
                    trailColor="#CCCCCC"
                    className="hide-percent-text"
                    style={{ width: "150px", fontWeight: "700px" }}
                  /> */}

                  <div className="progress-container">
                    <progress value={8} max="100">
                      75%
                    </progress>
                  </div>
                  <p className="font-bold leading-5 ml-2">+69%</p>
                </div>

                <div className="flex justify-between items-center mb-3">
                  <div className="h-7 w-7 text-[#199DE8] bg-[#199DE81A] rounded-[5] flex justify-center items-center">
                    <BiUpArrowAlt className="text-2xl" />
                  </div>
                  <div className="flex flex-col ml-2 w-40">
                    <p className="text-xs text-[#1B1C1E80] leading-5 font-medium">
                      Total Savings
                    </p>
                    <p className="font-bold leading-5">NGN 50,000</p>
                  </div>
                  {/* <Progress
                    percent={69}
                    color="#FF0000"
                    trailColor="#CCCCCC"
                    className="hide-percent-text"
                    style={{ width: "150px", fontWeight: "700px" }}
                  /> */}

                  <div className="progress-container">
                    <progress value={8} max="100">
                      75%
                    </progress>
                  </div>

                  <p className="font-bold leading-5 ml-2">+8%</p>
                </div>

                <div className="flex justify-between items-center mb-3">
                  <div className="h-7 w-7 text-[#F4410C] bg-[#199DE81A] rounded-[5] flex justify-center items-center">
                    <BiDownArrowAlt className="text-2xl" />
                  </div>
                  <div className="flex flex-col ml-2 w-40">
                    <p className="text-xs text-[#1B1C1E80] leading-5 font-medium">
                      Total Expenses
                    </p>
                    <p className="font-bold leading-5">NGN 500,000</p>
                  </div>
                  {/* <Progress
                    percent={69}
                    color="#199DE8"
                    trailColor="#199DE8"
                    className="hide-percent-text"
                    style={{ width: "150px", height: "20px" }}
                  /> */}

                  <div className="progress-container">
                    <progress value="0" max="100">
                      75%
                    </progress>
                  </div>

                  <p className="font-bold leading-5 ml-2">+69%</p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={7}>
          <Card className="border-none h-100">
            <CardBody>
              <div className="flex justify-between items-center mb-3">
                <p className="font-bold text-xl leading-8">Transactions</p>
              </div>

              <div className="flex flex-col">
                {allTimeTrans?.WalletTransactions?.slice(0, 3).map(
                  (trans, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center mb-4"
                    >
                      <div className="flex items-center">
                        <div className="h-7 w-7 text-[#199DE8] bg-[#199DE81A] rounded-[5] flex justify-center items-center">
                          <BiUpArrowAlt className="text-2xl" />
                        </div>
                        <div className="flex flex-col ml-4 w-40">
                          <p className="font-bold leading-5">{trans?.genus}</p>

                          <p className="text-xs text-[#1B1C1E80] leading-5 font-medium">
                            3 Jun, 2023 at 3:15PM
                          </p>
                        </div>
                      </div>

                      <p className="font-bold leading-5 text-[#68B61A]">
                        NGN {formatNumber(trans?.totalAmount)}
                      </p>
                    </div>
                  )
                )}
                {/* 
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center">
                    <div className="h-7 w-7 text-[#F4410C] bg-[#199DE81A] rounded-[5] flex justify-center items-center">
                      <BiDownArrowAlt className="text-2xl" />
                    </div>
                    <div className="flex flex-col ml-4 w-40">
                      <p className="font-bold leading-5">Groceries</p>

                      <p className="text-xs text-[#1B1C1E80] leading-5 font-medium">
                        3 Jun, 2023 at 3:15PM
                      </p>
                    </div>
                  </div>

                  <p className="font-bold leading-5 text-[#F4410C]">
                    -NGN 10,OOO
                  </p>
                </div> */}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Dashboard;
