import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import storesService from "./storesService";
import { RootStoresState } from "../../interfaces/storesInterface";

const initialState: RootStoresState = {
  products: {
    data: {
      pagination: {
        currentPage: 0,
        nextPage: 1,
        pageSize: 0,
        pageTotal: 0,
        prevPage: 1,
      },
      total: 0,
      products: [],
    },
  },
  stores: {
    data: {
      pagination: {
        currentPage: 0,
        nextPage: 1,
        pageSize: 0,
        pageTotal: 0,
        prevPage: 1,
      },
      total: 0,
      stores: [],
    },
  },
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  activate: null,
  deactivate: null,
};

// GET STORES
export const getAllStores = createAsyncThunk(
  "stores/getAll",
  async (params: { page: number; pageSize: number }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { page, pageSize } = params;
        return await storesService.getAllStores({ token, page, pageSize });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET STORE PRODUCTS
export const getAStoreProds = createAsyncThunk(
  "store/products",
  async (
    params: { storeId: number; page: number; pageSize: number },
    thunkAPI
  ) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { storeId, page, pageSize } = params;
        return await storesService.getStoreProduct({
          token,
          storeId,
          page,
          pageSize,
        });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deactivateStore = createAsyncThunk(
  "stores/deactivate",
  async (params: { id: number; data: any }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { id, data } = params;
        const response = await storesService.deactivateStore({
          token,
          id,
          data,
        });
        // Extract the necessary data from the response
        const deactivatedStore = response.data;
        return deactivatedStore;
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const activateStore = createAsyncThunk(
  "stores/activate",
  async (params: { id: number }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { id } = params;
        const response = await storesService.activateStore({
          token,
          id,
        });
        // Extract the necessary data from the response
        const activatedStore = response.data;
        return activatedStore;
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const storesSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStores.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllStores.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.stores = action.payload;
      })
      .addCase(getAllStores.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAStoreProds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAStoreProds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.products = action.payload;
      })
      .addCase(getAStoreProds.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deactivateStore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deactivateStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deactivate = action.payload;
      })
      .addCase(deactivateStore.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(activateStore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activateStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.activate = action.payload;
      })
      .addCase(activateStore.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = storesSlice.actions;
export default storesSlice.reducer;
