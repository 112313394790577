import React from "react";

interface ITitle {
  title: string;
}

const PageTitle: React.FC<ITitle> = ({ title }) => {
  return (
    <React.Fragment>
      <div className="title">
        <p className="title__text">{title}</p>
      </div>
    </React.Fragment>
  );
};

export default PageTitle;
