import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  // DropdownItem,
  // DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getTickets } from "../../features/tickets/ticketsSlice";
import Spinner from "../../components/Spinner";

const Support = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryPage = searchParams.get("page");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const { tickets, isLoading } = useAppSelector((state) => state.tickets);

  // Calculate the pagination range dynamically
  const calculatePaginationRange = () => {
    const total = tickets?.data?.pagination?.pageTotal;
    const currentPage = page;
    const range = 8;
    const totalPages = Math.ceil(total / pageSize);
    const middle = Math.ceil(range / 2);
    let start = Math.max(currentPage - middle, 1);
    let end = Math.min(start + range - 1, totalPages);

    if (end - start + 1 < range) {
      start = Math.max(end - range + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  useEffect(() => {
    dispatch(getTickets({ page, pageSize }));
  }, [page, pageSize]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <React.Fragment>
      <Card className="border-none">
        <CardBody>
          {tickets?.data?.tickets.length <= 0 ? (
            <p className="font-semibold text-2xl text-slate-500 text-center">
              No Ticket Available
            </p>
          ) : (
            tickets?.data?.tickets?.map((ticket) => (
              <Row className="mb-2" key={ticket?.id}>
                <div className="flex flex-col p-2 px-4 border-b border-[#1B1C1E1A]">
                  <div className="flex">
                    <div className="flex">
                      <div className="flex items-center">
                        <img
                          className="w-5 h-5 rounded-full"
                          src={
                            ticket?.image ||
                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                          alt=""
                        />
                        <p className="ml-2 font-bold">
                          {ticket?.ticketUser?.fullname ||
                            ticket?.ticketUser?.firstname ||
                            ticket?.ticketUser?.lastname}
                        </p>
                      </div>

                      {/* <div className="border-l ml-8 px-2">
                      <p className="font-medium text-[#1B1C1E80]">#23457654</p>
                    </div> */}
                    </div>
                  </div>

                  <div className="px-4">
                    <p className="font-medium">
                      {ticket?.complaint.slice(0, 60) + "..."}
                    </p>

                    <div className="flex items-center mt-4">
                      <p className="text-[#1B1C1E80] font-medium">
                        {new Date(ticket?.createdAt).toLocaleString([], {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </p>

                      <Button
                        color=""
                        className="ml-4 text-[#1B1C1E80] font-medium border-none"
                        onClick={() =>
                          navigate(`/admin/support-reply/${ticket?.id}`)
                        }
                      >
                        Reply
                      </Button>

                      <UncontrolledDropdown
                        className="me-2 ml-4"
                        direction="end"
                      >
                        <DropdownToggle
                          color=""
                          className={`${
                            !ticket.resolved
                              ? "text-red-300"
                              : "text-[#1B1C1E80]"
                          } font-medium border-none`}
                        >
                          {!ticket.resolved ? "Unresolved" : "Resolved"}
                        </DropdownToggle>
                        {/* <DropdownMenu className="custom-dropdown-menu border-none shadow-lg">
                        <DropdownItem>Mark as unread</DropdownItem>
                        <DropdownItem>Flag message</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Share</DropdownItem>
                      </DropdownMenu> */}
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
              </Row>
            ))
          )}
        </CardBody>
        <CardBody className="flex justify-between items-center">
          <select
            name=""
            id=""
            className="w-28 outline-none h-9 border border-[1B1C1E0D] rounded text-[#1B1C1E80]"
            onChange={(e) => setPageSize(parseInt(e.target.value))}
            value={pageSize}
          >
            <option value="10">show 10</option>
            <option value="20">show 20</option>
            <option value="30">show 30</option>
            <option value="40">show 40</option>
            <option value="50">show 50</option>
          </select>
          <Pagination
            aria-label="Page navigation example"
            className="custom-pagination"
            size="sm"
          >
            <PaginationItem className="mr-1" disabled={page === 1}>
              <PaginationLink
                href="#"
                previous
                onClick={() => setPage(page - 1)}
              />
            </PaginationItem>

            {calculatePaginationRange().map((pageNum) => (
              <PaginationItem
                key={pageNum}
                className="mr-1"
                active={pageNum === page}
              >
                <PaginationLink href="#" onClick={() => setPage(pageNum)}>
                  {pageNum}
                </PaginationLink>
              </PaginationItem>
            ))}

            {tickets?.data?.pagination?.pageTotal > 8 && (
              <PaginationItem>
                <PaginationLink href="#" onClick={() => setPage(page + 8)}>
                  ...
                </PaginationLink>
              </PaginationItem>
            )}

            <PaginationItem
              className="mr-1"
              disabled={page === tickets?.data?.pagination?.pageTotal}
            >
              <PaginationLink href="#" next onClick={() => setPage(page + 1)} />
            </PaginationItem>
          </Pagination>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Support;
