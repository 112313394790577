import { RootProductsState } from "./../../interfaces/productInterface";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productService from "./productService";

const initialState: RootProductsState = {
  products: {
    data: {
      pagination: {
        currentPage: 0,
        nextPage: 1,
        pageSize: 0,
        pageTotal: 0,
        prevPage: 1,
      },
      total: 0,
      products: [],
    },
  },

  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  banProd: null,
  permitProd: null,
  searchResults: "",
};

// Action to search products by name
export const searchProductsByName = createAsyncThunk(
  "productSearch/searchByName",
  async (params: { token: string; name: string }, thunkAPI) => {
    try {
      const { token, name } = params;
      const response = await productService.searchProductsByName({
        token,
        name,
      });
      return response;
    } catch (error: any) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// GET ALL PRODUCTS
export const getAllProducts = createAsyncThunk(
  "allProducts/getAll",
  async (params: { page: number; pageSize: number }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { page, pageSize } = params;
        return await productService.getAllProducts({ token, page, pageSize });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const banProduct = createAsyncThunk(
  "product/ban",
  async (params: { storeId: number; id: number; data: any }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { storeId, id, data } = params;
        const response = await productService.banProduct({
          token,
          storeId,
          id,
          data,
        });

        // Extract the necessary data from the response
        const bannedProduct = response.data;
        return bannedProduct;
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const permitProduct = createAsyncThunk(
  "product/permit",
  async (params: { storeId: number; id: number }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { storeId, id } = params;
        const response = await productService.permitProduct({
          token,
          storeId,
          id,
        });
        // Extract the necessary data from the response
        const bannedProduct = response.data;
        return bannedProduct;
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchProductsByName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchProductsByName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.searchResults = action.payload;
      })
      .addCase(searchProductsByName.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.products = action.payload;
      })
      .addCase(getAllProducts.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(banProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(banProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.banProd = action.payload;
      })
      .addCase(banProduct.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(permitProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(permitProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permitProd = action.payload;
      })
      .addCase(permitProduct.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = productsSlice.actions;
export default productsSlice.reducer;
