import React, { useState } from "react";
import { SearchBar } from "../components/SearchBar";
import { GoBell } from "react-icons/go";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store/hooks";
import { logout, reset } from "../features/authentication/authSlice";

const userJSON = localStorage?.getItem("user");
const user = userJSON !== null ? JSON.parse(userJSON) : null;

console.log({ user });

const Header = ({ onSearch }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen((prevState: any) => !prevState);
  };
  const location = useLocation();

  const isActive = location.pathname === "/";

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  };

  return (
    <div className="header">
      <div className={`flex items-center  ${!isActive && "w-8/12"}`}>
        <p className={`${isActive ? "header__title" : "hidden"}`}>Dashboard</p>
        <div className={`${isActive ? "w-96 ml-10" : "w-full"}`}>
          <SearchBar onSearch={onSearch} />
        </div>
      </div>

      <div className="">
        <div className="header__profile">
          <div className="bell">
            <GoBell />
            <div className="bell-dot" />
          </div>

          <div
            className="profile cursor-pointer relative"
            onClick={toggleDropdown}
            onBlur={() => setDropdownOpen(false)} // To close the dropdown when clicked outside
            tabIndex={0} // To make the div focusable for onBlur event
          >
            <div className="flex justify-center items-center">
              <img
                className="w-7 h-7 mr-3 rounded-[5px]"
                src={
                  user?.data?.avatar ||
                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                }
                alt=""
              />

              <div className="text-center">
                <p className="font-semibold text-sm m-0">
                  {user?.data?.user?.fullname}
                </p>
                <p className="text-[10px] m-0">PXN admin</p>
              </div>

              <div className="ml-3">
                <MdKeyboardArrowDown />
              </div>
            </div>

            {/* Dropdown Content */}
            {dropdownOpen && (
              <div className="absolute right-0 top-14 w-48 bg-white rounded-md text-center shadow-lg  z-10">
                {/* <Link
                  to="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:text-[#199DE8] hover:bg-[#199DE81A] transition duration-150 ease-in-out"
                >
                  View profile
                </Link>
                <hr className="border-slate-200" /> */}
                <div
                  onClick={onLogout}
                  className="block px-4 py-2 text-sm text-gray-700 hover:text-red-800 hover:bg-red-300 rounded-md transition duration-150 ease-in-out"
                >
                  Log out
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
