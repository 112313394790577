import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getAdmin } from "../../features/admin/adminSlice";

const PieChart = () => {
  const dispatch = useAppDispatch();
  const { admin } = useAppSelector((state) => state.admin);

  const totalMale = admin?.data?.males;
  const totalFemale = admin?.data?.females;

  // Data for the pie chart
  const data = {
    datasets: [
      {
        data: [totalMale, totalFemale],
        backgroundColor: ["#199DE8", "#199DE880"],
        borderWidth: 0,
      },
    ],
  };

  // Options for the pie chart
  const options = {
    plugins: {
      title: {
        display: false,
        text: "Male and Female Distribution",
        font: {
          size: 20,
        },
      },
    },
    cutout: "85%",
    legend: {
      display: true,
      position: "bottom",
    },
  };

  const chartRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (
      chartRef.current &&
      totalMale !== undefined &&
      totalFemale !== undefined
    ) {
      // Check if chartRef.current exists and data is available before rendering the chart
      const context = chartRef.current.getContext("2d");
      if (context) {
        const previousChart = Chart.getChart(context);
        if (previousChart) {
          previousChart.destroy();
        }
      }

      new Chart(chartRef.current, {
        type: "doughnut",
        data,
        options,
      });
    }
  }, [totalMale, totalFemale]);

  useEffect(() => {
    dispatch(getAdmin());
  }, [dispatch]);

  return <canvas ref={chartRef} />;
};

const RadialBarChart = () => {
  return (
    <div className="flex justify-center items-center w-full">
      <PieChart />
    </div>
  );
};

export default RadialBarChart;
