import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { PageTitle } from "../../components/PageTitle";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { UserProfileModal } from "../../components/Modals";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getAllUsers,
  reinstateUser,
  suspendUser,
} from "../../features/users/usersSlice";
import { useSearchParams } from "react-router-dom";
import Spinner from "../../components/Spinner";

const Users = () => {
  const dispatch = useAppDispatch();
  const { users, isLoading } = useAppSelector((state) => state.users);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const queryPage = searchParams.get("page");
  const pxnUsers = users?.data?.users;

  console.log({ users });

  // Calculate the pagination range dynamically
  const calculatePaginationRange = () => {
    const total = users?.data?.pagination?.pageTotal;
    const currentPage = page;
    const range = 8;
    const totalPages = Math.ceil(total / pageSize);
    const middle = Math.ceil(range / 2);
    let start = Math.max(currentPage - middle, 1);
    let end = Math.min(start + range - 1, totalPages);

    if (end - start + 1 < range) {
      start = Math.max(end - range + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const handleUserAction = async (user: any, actionType: any) => {
    if (actionType) {
      await dispatch(suspendUser(user?.id));
    } else {
      await dispatch(reinstateUser(user?.id));
    }

    // Fetch the updated user list immediately
    await dispatch(getAllUsers({ page, pageSize }));
  };

  useEffect(() => {
    dispatch(getAllUsers({ page, pageSize }));
  }, [page, pageSize]);

  useEffect(() => {
    if (queryPage === null) {
      setSearchParams({ page: page.toString() });
    } else {
      setPage(parseInt(queryPage));
    }
    setPageSize(50);
  }, [queryPage, setSearchParams]);

  return (
    <React.Fragment>
      <Row className="">
        <PageTitle title="Users List" />
      </Row>

      {isLoading ? (
        <Spinner />
      ) : (
        <Row className="mt-4">
          <Col>
            <Card className="border-none">
              <Table hover>
                <thead>
                  <tr>
                    <th className="header-cell">Name</th>
                    <th className="header-cell">Email</th>
                    <th className="header-cell">Phone Number</th>
                    <th className="header-cell">Gender</th>
                    <th className="header-cell">Order Status</th>
                    <th className="header-cell">
                      <MdOutlineKeyboardArrowDown />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pxnUsers?.length <= 0 ? (
                    <tr>There are no users available</tr>
                  ) : (
                    pxnUsers?.map((user, index) => (
                      <tr className="" key={index}>
                        <td className="table-body-data" scope="row">
                          <div className="table-data-image">
                            <img
                              className="table-image"
                              src={
                                user?.avatar
                                  ? user?.avatar
                                  : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                              }
                              alt={user?.firstname}
                            />{" "}
                            {user?.fullname}
                          </div>
                        </td>
                        <td className="table-body-data">{user?.email}</td>
                        <td className="table-body-data">{user?.phone}</td>
                        <td className="table-body-data">
                          <div className="gender-pill-male">
                            {" "}
                            {user.gender === "M"
                              ? "Male"
                              : user.gender === "F"
                              ? "Female"
                              : "Others"}
                          </div>
                        </td>
                        <td className="table-body-data">
                          <div
                            className={`${
                              user?.suspended
                                ? "status-pill-suspended"
                                : "status-pill-approved"
                            }`}
                          >
                            {user?.suspended ? "Suspended" : "Active"}
                          </div>
                        </td>
                        <td className="table-body-data">
                          <UserProfileModal
                            text={user.suspended ? "Reinstate" : "Suspend"}
                            onDelete={() => {
                              handleUserAction(user, !user.suspended);
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
              <CardBody className="flex justify-between items-center">
                <select
                  name=""
                  id=""
                  className="w-28 outline-none h-9 border border-[1B1C1E0D] rounded text-[#1B1C1E80]"
                  onChange={(e) => setPageSize(parseInt(e.target.value))}
                  value={pageSize}
                >
                  <option value="10">show 10</option>
                  <option value="20">show 20</option>
                  <option value="30">show 30</option>
                  <option value="40">show 40</option>
                  <option value="50">show 50</option>
                </select>
                <Pagination
                  aria-label="Page navigation example"
                  className="custom-pagination"
                  size="sm"
                >
                  <PaginationItem className="mr-1" disabled={page === 1}>
                    <PaginationLink
                      href="#"
                      previous
                      onClick={() => setPage(page - 1)}
                    />
                  </PaginationItem>

                  {calculatePaginationRange().map((pageNum) => (
                    <PaginationItem
                      key={pageNum}
                      className="mr-1"
                      active={pageNum === page}
                    >
                      <PaginationLink href="#" onClick={() => setPage(pageNum)}>
                        {pageNum}
                      </PaginationLink>
                    </PaginationItem>
                  ))}

                  {users?.data?.pagination?.pageTotal > 8 && (
                    <PaginationItem>
                      <PaginationLink
                        href="#"
                        onClick={() => setPage(page + 8)}
                      >
                        ...
                      </PaginationLink>
                    </PaginationItem>
                  )}

                  <PaginationItem
                    className="mr-1"
                    disabled={page === users?.data?.pagination?.pageTotal}
                  >
                    <PaginationLink
                      href="#"
                      next
                      onClick={() => setPage(page + 1)}
                    />
                  </PaginationItem>
                </Pagination>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default Users;
