import axios from "axios";
import { IAuthenticate } from "../../interfaces/authInterface";

// const API_URL = "https://pxn-backend.herokuapp.com/api/v2/auth/login";
const API_URL = "https://pxn-api-zzpou.ondigitalocean.app/api/v2/auth/login";

// LOGIN USER
const login = async (userData: IAuthenticate) => {
  const response = await axios.post(API_URL, userData);

  if (response?.data) {
    localStorage?.setItem("user", JSON?.stringify(response?.data));
  }

  return response?.data;
};

// logout user
const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  login,
  logout,
};

export default authService;
