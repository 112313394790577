import React, { useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";

const OrdersActions = ({ onDelete, onEdit }: any) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const show = () => {
    setShowDropDown(!showDropDown);
  };

  return (
    <React.Fragment>
      <div
        onClick={show}
        className="w-fit relative hover:shadow-md p-2 flex items-center justify-center hover:p-1 cursor-pointer hover:bg-white hover:rounded-full transition-all duration-500"
      >
        <div
          className={`absolute top-0 right-5 w-36 rounded-md ${
            showDropDown ? "flex" : "hidden"
          } flex-col action-shadow`}
        >
          <div
            onClick={onEdit}
            className="cursor-pointer  w-full bg-blue-200 p-1 flex items-center justify-center text-sm rounded-t-md"
          >
            <div className="w-[50%]">
              <p>Activated</p>
            </div>
          </div>
          <div
            onClick={onDelete}
            className=" cursor-pointer w-full bg-white p-1 flex items-center justify-center text-sm rounded-b-md"
          >
            <div className="w-[50%]">
              <p>Deactivate</p>
            </div>
          </div>
        </div>
        <SlOptionsVertical className="text-xs cursor-pointer" />
      </div>
    </React.Fragment>
  );
};

export default OrdersActions;
