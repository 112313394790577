import axios from "axios";

const API_URL = "https://pxn-api-zzpou.ondigitalocean.app/api/v2/";
// const API_URL = "https://pxn-backend.herokuapp.com/api/v2/";

interface IParams {
  token: string;
  page: number;
  pageSize: number;
}

interface IBan {
  token: string;
  storeId: number;
  id: number;
  data: any;
}

interface IPermit {
  token: string;
  storeId: number;
  id: number;
}

const searchProductsByName = async ({
  token,
  name,
}: {
  token: string;
  name: string;
}) => {
  const params = {
    name,
  };

  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(API_URL + "store/product/all", {
      params,
      headers: config.headers,
    });

    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error searching for products by name:", error);
    throw error;
  }
};

// get all products
const getAllProducts = async ({ token, page, pageSize }: IParams) => {
  const params = {
    page,
    pageSize,
  };

  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(API_URL + "store/product/all", {
      params,
      headers: config.headers,
    });

    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching products:", error);
    throw error;
  }
};

// ban a product
const banProduct = async ({ token, storeId, id, data }: IBan) => {
  const config = {
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.put(
      API_URL + `store/${storeId}/product/${id}/ban`,
      data,
      config
    );

    console.log(response, "banned");
    return response;
  } catch (error) {
    console.error("Error banning product:", error);
    throw error;
  }
};

// permit a product
const permitProduct = async ({ token, storeId, id }: IPermit) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.put(
      API_URL + `store/${storeId}/product/${id}/permit`,
      {},
      {
        headers: config.headers,
      }
    );
    console.log(response, "permited");
    return response;
  } catch (error) {
    console.error("Error permitting product:", error);
    throw error;
  }
};

const productService = {
  searchProductsByName,
  getAllProducts,
  banProduct,
  permitProduct,
};

export default productService;
