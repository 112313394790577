import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { PageTitle } from "../../components/PageTitle";
import { IoIosArrowDown } from "react-icons/io";
// import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getTransactionsHistory } from "../../features/transactions/transactionsSlice";
import Spinner from "../../components/Spinner";
import { useSearchParams } from "react-router-dom";

const Transactions = () => {
  const dispatch = useAppDispatch();
  // const [expandedRow, setExpandedRow] = useState(null);
  const { transactions, isLoading } = useAppSelector(
    (state) => state.transactions
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [amountModalOpen, setAmountModalOpen] = useState(false);
  const [startAmount, setStartAmount] = useState(0);
  const [endAmount, setEndAmount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryPage = searchParams.get("page");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const formatDate = (dateStr: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = new Date(dateStr).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  console.log({ transactions });

  // Calculate the pagination range dynamically
  const calculatePaginationRange = () => {
    const total = transactions?.data?.pagination?.pageTotal;
    const currentPage = page;
    const range = 8;
    const totalPages = Math.ceil(total / pageSize);
    const middle = Math.ceil(range / 2);
    let start = Math.max(currentPage - middle, 1);
    let end = Math.min(start + range - 1, totalPages);

    if (end - start + 1 < range) {
      start = Math.max(end - range + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setStartDate("");
    setEndDate("");
  };

  const handleStartDateChange = (event: any) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event: any) => {
    setEndDate(event.target.value);
  };

  const applyDateRange = () => {
    // Close the modal
    toggleModal();
    dispatch(
      getTransactionsHistory({
        page,
        pageSize,
        startDate,
        endDate,
        startAmount,
        endAmount,
      })
    );
  };

  const toggleAmountModal = () => {
    setAmountModalOpen(!amountModalOpen);
    setStartAmount(0);
    setEndAmount(0);
  };

  const handleStartAmountChange = (event: any) => {
    setStartAmount(event.target.value);
  };

  const handleEndAmountChange = (event: any) => {
    setEndAmount(event.target.value);
  };

  const applyAmountRange = () => {
    // Close the modal
    toggleAmountModal();
    dispatch(
      getTransactionsHistory({
        page,
        pageSize,
        startDate,
        endDate,
        startAmount,
        endAmount,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getTransactionsHistory({
        page,
        pageSize,
        startDate,
        endDate,
        startAmount,
        endAmount,
      })
    );
  }, [page, pageSize]);

  useEffect(() => {
    if (queryPage === null) {
      setSearchParams({ page: page.toString() });
    } else {
      setPage(parseInt(queryPage));
    }
    setPageSize(50);
  }, [queryPage, setSearchParams]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <Row className="">
        <PageTitle title="Transaction History" />
      </Row>

      <Row className="mt-4 flex items-center">
        <Col md={8}>
          <div className="flex">
            <div>
              <Card className="bg-transparent border-none cursor-pointer">
                <CardBody onClick={toggleModal}>
                  <div className="flex justify-center items-center">
                    <div>
                      <Card className="flex border-none justify-center items-center py-2 px-4 h-full">
                        <p className="text-[#1B1C1E80]">Date Range</p>
                      </Card>
                    </div>
                    <div className="ml-3">
                      <Card className="flex border-none justify-center items-center py-2 px-2 h-full">
                        <IoIosArrowDown className="text-[#1B1C1E80]" />
                      </Card>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            {/* <div>
              <Card className="bg-transparent border-none cursor-pointer">
                <CardBody>
                  <div className="flex justify-center items-center">
                    <div>
                      <Card className="flex border-none justify-center items-center py-2 px-4 h-full">
                        <p className="text-[#1B1C1E80]">Status</p>
                      </Card>
                    </div>
                    <div className="ml-3">
                      <Card className="flex border-none justify-center items-center py-2 px-2 h-full">
                        <IoIosArrowDown className="text-[#1B1C1E80]" />
                      </Card>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div> */}
            <div>
              <Card className="bg-transparent border-none cursor-pointer">
                <CardBody onClick={toggleAmountModal}>
                  <div className="flex justify-center items-center">
                    <div>
                      <Card className="flex border-none justify-center items-center py-2 px-4 h-full">
                        <p className="text-[#1B1C1E80]">Amount Range</p>
                      </Card>
                    </div>
                    <div className="ml-3">
                      <Card className="flex border-none justify-center items-center py-2 px-2 h-full">
                        <IoIosArrowDown className="text-[#1B1C1E80]" />
                      </Card>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>

        <Col md={4}>
          <div className="flex justify-end">
            <Button
              color="primary"
              size="sm"
              className="bg-[#199DE8] border-[#199DE8] w-20"
            >
              Print
            </Button>
            <Button
              color="primary"
              size="sm"
              className="bg-[#199DE8] border-[#199DE8] ml-3 w-20"
            >
              Export
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="border-none">
            <Table hover>
              <thead>
                <tr>
                  <th className="header-cell">Transaction Date & Time</th>
                  <th className="header-cell">Genus</th>
                  <th className="header-cell">Transaction ID</th>
                  {/* <th className="header-cell">Recipient Name</th> */}
                  {/* <th className="header-cell">Status</th> */}
                  <th className="header-cell">Amount</th>
                  {/* <th className="header-cell">
                    <MdOutlineKeyboardArrowDown />
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {transactions?.data?.transactions?.map((item) => (
                  <tr className="" key={item.id}>
                    <td className="table-body-data" scope="row">
                      {formatDate(item?.createdAt)}
                    </td>
                    <td className="table-body-data">{item?.genus}</td>
                    <td className="table-body-data">{item?.reference}</td>
                    {/* <td className="table-body-data">Musa Adamu</td> */}
                    {/* <td className="table-body-data">
                      <div className="status-pill-approved">
                        {item?.confirmed}
                      </div>
                    </td> */}
                    <td className="table-body-data">&#x20A6;{item?.amount}</td>
                    {/* <td
                    className="table-body-data cursor-pointer"
                    onClick={() => toggleCard(0)}
                  >
                    <MdOutlineKeyboardArrowDown />
                  </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>

            <CardBody className="flex justify-between items-center">
              <select
                name=""
                id=""
                className="w-28 outline-none h-9 border border-[1B1C1E0D] rounded text-[#1B1C1E80]"
                onChange={(e) => setPageSize(parseInt(e.target.value))}
                value={pageSize}
              >
                <option value="10">show 10</option>
                <option value="20">show 20</option>
                <option value="30">show 30</option>
                <option value="40">show 40</option>
                <option value="50">show 50</option>
              </select>
              <Pagination
                aria-label="Page navigation example"
                className="custom-pagination"
                size="sm"
              >
                <PaginationItem className="mr-1" disabled={page === 1}>
                  <PaginationLink
                    href="#"
                    previous
                    onClick={() => setPage(page - 1)}
                  />
                </PaginationItem>

                {calculatePaginationRange().map((pageNum) => (
                  <PaginationItem
                    key={pageNum}
                    className="mr-1"
                    active={pageNum === page}
                  >
                    <PaginationLink href="#" onClick={() => setPage(pageNum)}>
                      {pageNum}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                {transactions?.data?.pagination?.pageTotal > 8 && (
                  <PaginationItem>
                    <PaginationLink href="#" onClick={() => setPage(page + 8)}>
                      ...
                    </PaginationLink>
                  </PaginationItem>
                )}

                <PaginationItem
                  className="mr-1"
                  disabled={page === transactions?.data?.pagination?.pageTotal}
                >
                  <PaginationLink
                    href="#"
                    next
                    onClick={() => setPage(page + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Add the Amount Range Modal */}
      <Modal isOpen={amountModalOpen} toggle={toggleAmountModal}>
        <ModalHeader toggle={toggleAmountModal}>Set Amount Range</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label htmlFor="startAmount">Start Amount:</label>
            <input
              type="number"
              id="startAmount"
              className="form-control"
              value={startAmount}
              onChange={handleStartAmountChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="endAmount">End Amount:</label>
            <input
              type="number"
              id="endAmount"
              className="form-control"
              value={endAmount}
              onChange={handleEndAmountChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="text-blue-400"
            color="primary"
            onClick={applyAmountRange}
          >
            Apply
          </Button>
          <Button
            className="text-gray-500"
            color="secondary"
            onClick={toggleAmountModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Add the Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Select Date Range</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label htmlFor="startDate">Start Date:</label>
            <input
              type="date"
              id="startDate"
              className="form-control"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="endDate">End Date:</label>
            <input
              type="date"
              id="endDate"
              className="form-control"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="text-blue-400"
            onClick={applyDateRange}
          >
            Apply
          </Button>
          <Button
            color="secondary"
            className="text-gray-500"
            onClick={toggleModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Transactions;
