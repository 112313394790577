import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col } from "reactstrap";

interface ICounter {
  md: number;
  title: string;
  counter: number;
  counterFormatter?: (value: number) => string;
}
const CounterFormater = ({
  md,
  title,
  counter,
  counterFormatter,
}: ICounter) => {
  const [count, setCount] = useState(0);

  function formatMoney(amount: number, decimalPlaces = 2) {
    const formattedAmount = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: "NGN",
      currencyDisplay: "symbol",
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }).format(amount);

    return formattedAmount;
  }

  useEffect(() => {
    // Function to update the count with animation
    const updateCount = () => {
      const step = Math.ceil(counter / 50); // Adjust the step as needed
      if (count < counter) {
        setCount((prevCount) => Math.min(prevCount + step, counter));
      }
    };

    // Create an interval to update the count
    const interval = setInterval(updateCount, 30);

    // Clear the interval when the component unmounts or the count reaches the total value
    return () => clearInterval(interval);
  }, [count, counter]);

  return (
    <React.Fragment>
      <Col md={md}>
        <Card className="border-none">
          <CardBody className="text-center">
            <CardTitle className="font-semibold text-xl text-[#1B1C1E80]">
              {title}
            </CardTitle>
            <p className="text-xl font-semibold">
              {counterFormatter
                ? counterFormatter(counter)
                : formatMoney(counter)}
            </p>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CounterFormater;
