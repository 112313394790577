import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import OrdersActions from "../Orders/OrdersActions";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { PageTitle } from "../../components/PageTitle";
import { RidersProfileModal } from "../../components/Modals";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getCompanyRiders } from "../../features/logistics/logisticsSlice";

const Riders = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { logistics } = useAppSelector((state) => state.logistics);

  const llo = logistics?.data?.companies?.map((company) =>
    company?.riders?.map((rider) => rider.email)
  );

  console.log({ llo });

  console.log({ logistics });

  useEffect(() => {
    if (id) {
      const companyId = parseInt(id, 10);

      if (!isNaN(companyId)) {
        dispatch(getCompanyRiders(companyId));
      }
    }
  }, [id, dispatch]);

  return (
    <React.Fragment>
      <Row className="">
        <PageTitle title="Riders List" />
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="border-none">
            <Table hover>
              <thead>
                <tr>
                  <th className="header-cell">Name</th>
                  <th className="header-cell">Email</th>
                  <th className="header-cell">Phone Number</th>
                  <th className="header-cell">Gender</th>
                  <th className="header-cell">Status</th>
                  <th className="header-cell">
                    <MdOutlineKeyboardArrowDown />
                  </th>
                </tr>
              </thead>
              <tbody>
                {logistics?.data?.companies?.map((company) =>
                  company?.riders?.map((rider) => (
                    <tr className="" key={rider.id}>
                      <td className="table-body-data" scope="row">
                        {rider?.fullname}
                      </td>
                      <td className="table-body-data">{rider.email}</td>
                      <td className="table-body-data">{rider.phone}</td>
                      <td className="table-body-data">
                        <div className="gender-pill-male">Male</div>
                      </td>
                      <td className="table-body-data">
                        <div
                          className={
                            rider?.status === "unavailable"
                              ? "status-pill-suspended"
                              : "status-pill-approved"
                          }
                        >
                          {rider?.status}
                        </div>
                      </td>
                      <td className="table-body-data">
                        <OrdersActions onDelete={""} onEdit={""} />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>

            <CardBody className="flex justify-between items-center">
              <select
                name=""
                id=""
                className="w-28 outline-none h-9 border border-[1B1C1E0D] rounded text-[#1B1C1E80]"
              >
                <option value="">show 10</option>
                <option value="">show 20</option>
                <option value="">show 30</option>
                <option value="">show 40</option>
              </select>
              <Pagination
                aria-label="Page navigation example"
                className="custom-pagination"
                size="sm"
              >
                <PaginationItem className="mr-1" disabled>
                  <PaginationLink href="#" previous />
                </PaginationItem>
                <PaginationItem className="mr-1" active>
                  <PaginationLink href="#">1</PaginationLink>
                </PaginationItem>
                <PaginationItem className="mr-1">
                  <PaginationLink href="#">2</PaginationLink>
                </PaginationItem>
                <PaginationItem className="mr-1">
                  <PaginationLink href="#">3</PaginationLink>
                </PaginationItem>
                <PaginationItem className="mr-1">
                  <PaginationLink href="#">4</PaginationLink>
                </PaginationItem>
                <PaginationItem className="mr-1">
                  <PaginationLink href="#">5</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="#" next />
                </PaginationItem>
              </Pagination>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Riders;
