import React from "react";

const VerticalProgressBar = ({ data }: any) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

  const isActive = months.map((month) => month === "Feb");

  return (
    <div className="prog-bar">
      {months.map((month, index) => (
        <div key={index} className="prog-container">
          <div className="flex flex-col-reverse items-center h-full">
            <div
              className={`${isActive[index] ? "prog" : "prog-inactive"}`}
              style={{ height: `${data[index]}%` }}
            />
            <div
              className={`${
                isActive[index] ? "bg-[#EC5E0F] px-2 rounded-md" : "hidden"
              }`}
            >
              <p className={``}>{data[index]}%</p>
            </div>
          </div>
          <div className="prog-label">{month}</div>
        </div>
      ))}
    </div>
  );
};

export default VerticalProgressBar;
