import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  activateCompany,
  deactivateCompany,
  getAllLogistics,
} from "../../features/logistics/logisticsSlice";
import { format } from "date-fns";
import { Counter } from "../../components/Counter";
import { getAdmin } from "../../features/admin/adminSlice";
import Spinner from "../../components/Spinner";
import LogisticsAction from "./LogisticsAction";

const initialState = { reason: "" };

const Logistics = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { admin } = useAppSelector((state) => state.admin);
  const { logistics, isLoading } = useAppSelector((state) => state.logistics);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryPage = searchParams.get("page");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [isBanModalOpen, setIsBanModalOpen] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [companyId, setCompanyId] = useState<number>(0);
  const { reason } = formData;

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const logisticCompanies = admin?.data?.companies;

  const formatMoney = (amount: any) => {
    const formatter = new Intl.NumberFormat("en-NG", {
      style: "decimal",
      minimumFractionDigits: 2,
    });

    return formatter.format(amount);
  };

  // Calculate the pagination range dynamically
  const calculatePaginationRange = () => {
    const total = logistics?.data?.pagination?.pageTotal;
    const currentPage = page;
    const range = 8;
    const totalPages = Math.ceil(total / pageSize);
    const middle = Math.ceil(range / 2);
    let start = Math.max(currentPage - middle, 1);
    let end = Math.min(start + range - 1, totalPages);

    if (end - start + 1 < range) {
      start = Math.max(end - range + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const handleCompanyAction = async (id: number, actionType: any) => {
    if (actionType === true || actionType === null) {
      setIsBanModalOpen(true);

      setCompanyId(id);
    } else if (actionType === false) {
      await dispatch(activateCompany({ id }));
      // Fetch the updated user list immediately
      await dispatch(getAllLogistics({ page, pageSize }));
    }
  };

  // Function to handle ban submission
  const handleBanSubmission = async () => {
    const data = {
      reason: formData.reason,
    };

    await dispatch(
      deactivateCompany({
        id: companyId,
        data,
      })
    );

    // Close the ban modal
    setIsBanModalOpen(false);

    // Fetch the updated product list
    await dispatch(getAllLogistics({ page, pageSize }));
  };

  useEffect(() => {
    dispatch(getAllLogistics({ page, pageSize }));
    dispatch(getAdmin());
  }, [page, pageSize]);

  useEffect(() => {
    if (queryPage === null) {
      setSearchParams({ page: page.toString() });
    } else {
      setPage(parseInt(queryPage));
    }
    setPageSize(50);
  }, [queryPage, setSearchParams]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <Row className="">
        <Counter
          md={5}
          counter={logisticCompanies}
          title="Registered Logistics Companies"
        />
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="border-none">
            <Table hover>
              <thead>
                <tr>
                  <th className="header-cell">
                    <div className="flex items-center">
                      Logistics Companies{" "}
                      {/* <p className="text-[#F4410C] flex text-xs justify-center items-center rounded-full p-1 bg-white ml-1 w-5 h-5">
                        3
                      </p> */}
                    </div>
                  </th>
                  <th className="header-cell">Date</th>
                  <th className="header-cell">Status</th>
                  <th className="header-cell">Revenue</th>
                  <th className="header-cell">Net Profit</th>
                  <th className="header-cell">Actions</th>
                </tr>
              </thead>
              <tbody>
                {logistics?.data?.companies?.map((item) => (
                  <tr
                    key={item?.id}
                    className={`${
                      item?.active ? "" : "disabled"
                    } cursor-pointer`}
                  >
                    <td
                      className="table-body-data"
                      scope="row"
                      onClick={() => {
                        navigate(`/admin/logistics/${item?.id}`);
                      }}
                    >
                      {item?.name}
                    </td>
                    <td
                      className="table-body-data"
                      onClick={() => {
                        navigate(`/admin/logistics/${item?.id}`);
                      }}
                    >
                      {item?.manager?.createdAt
                        ? format(
                            new Date(item?.manager?.createdAt),
                            "MMM d, yyyy"
                          )
                        : "Invalid Date"}
                    </td>
                    <td
                      className="table-body-data"
                      onClick={() => {
                        navigate(`/admin/logistics/${item?.id}`);
                      }}
                    >
                      {item?.manager?.status}
                    </td>
                    <td
                      className="table-body-data"
                      onClick={() => {
                        navigate(`/admin/logistics/${item?.id}`);
                      }}
                    >
                      &#x20A6;{formatMoney(item?.wallet?.balance)}
                    </td>
                    <td
                      className="table-body-data"
                      onClick={() => {
                        navigate(`/admin/logistics/${item?.id}`);
                      }}
                    >
                      &#x20A6;
                      {formatMoney(
                        item?.manager?.walletTransactions?.map(
                          (netP) => netP?.totalAmount
                        ) || 0
                      )}
                    </td>
                    <td className="table-body-data">
                      <LogisticsAction
                        text={
                          item?.active === null || item?.active === true
                            ? "Deactivate"
                            : "Activate"
                        }
                        onBan={() =>
                          handleCompanyAction(item?.id, item?.active)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <CardBody className="flex justify-between items-center">
              <select
                name=""
                id=""
                className="w-28 outline-none h-9 border border-[1B1C1E0D] rounded text-[#1B1C1E80]"
                onChange={(e) => setPageSize(parseInt(e.target.value))}
                value={pageSize}
              >
                <option value="10">show 10</option>
                <option value="20">show 20</option>
                <option value="30">show 30</option>
                <option value="40">show 40</option>
                <option value="50">show 50</option>
              </select>
              <Pagination
                aria-label="Page navigation example"
                className="custom-pagination"
                size="sm"
              >
                <PaginationItem className="mr-1" disabled={page === 1}>
                  <PaginationLink
                    href="#"
                    previous
                    onClick={() => setPage(page - 1)}
                  />
                </PaginationItem>

                {calculatePaginationRange().map((pageNum) => (
                  <PaginationItem
                    key={pageNum}
                    className="mr-1"
                    active={pageNum === page}
                  >
                    <PaginationLink href="#" onClick={() => setPage(pageNum)}>
                      {pageNum}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                {logistics?.data?.pagination?.pageTotal > 8 && (
                  <PaginationItem>
                    <PaginationLink href="#" onClick={() => setPage(page + 8)}>
                      ...
                    </PaginationLink>
                  </PaginationItem>
                )}

                <PaginationItem
                  className="mr-1"
                  disabled={page === logistics?.data?.pagination?.pageTotal}
                >
                  <PaginationLink
                    href="#"
                    next
                    onClick={() => setPage(page + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* Ban Modal */}
      <Modal
        isOpen={isBanModalOpen}
        toggle={() => setIsBanModalOpen(!isBanModalOpen)}
      >
        <ModalHeader toggle={() => setIsBanModalOpen(!isBanModalOpen)}>
          Ban Product
        </ModalHeader>
        <ModalBody>
          <p>Enter the reason for deactivatiing the company:</p>
          <Input type="text" name="reason" value={reason} onChange={onChange} />
        </ModalBody>
        <ModalFooter>
          <Button className="bg-danger" onClick={handleBanSubmission}>
            Deactivate company
          </Button>{" "}
          <Button
            className="bg-secondary"
            onClick={() => setIsBanModalOpen(!isBanModalOpen)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Logistics;
