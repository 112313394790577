import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import transactionsService from "./transactionsService";
import RootTransByMonthState from "../../interfaces/transByMonthInterface";

interface IHistory {
  page: number;
  pageSize: number;
  startDate: string;
  endDate: string;
  startAmount: number;
  endAmount: number;
}

const initialState: RootTransByMonthState = {
  transactions: {
    data: {
      pagination: {
        currentPage: 0,
        nextPage: 1,
        pageSize: 0,
        pageTotal: 0,
        prevPage: 1,
      },
      transactions: [],
    },
  },

  WalletTransactions: {
    WalletTransactions: [],
  },

  allTimeTrans: {
    WalletTransactions: [],
  },

  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// GET TRANSACTIONS HISTORY
export const getTransactionsHistory = createAsyncThunk(
  "transactions/transHistory",
  async (params: IHistory, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        return await transactionsService.getTransactionHistory({
          token,
          page: params.page,
          pageSize: params.pageSize,
          startDate: params.startDate,
          endDate: params.endDate,
          startAmount: params.startAmount,
          endAmount: params.endAmount,
        });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET TRANSACTIONS BY MONTH
export const getTransactions = createAsyncThunk(
  "transactions/getAll",
  async (year: number, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        return await transactionsService.getTransbyMonth({ token, year });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET ALL TIME TRANSACTIONS
export const getTransAllTime = createAsyncThunk(
  "allTimeTrans/getAll",
  async (_, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        return await transactionsService.getAllTimeTrans(token);
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionsHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransactionsHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.transactions = action.payload;
      })
      .addCase(getTransactionsHistory.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.WalletTransactions = action.payload;
      })
      .addCase(getTransactions.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getTransAllTime.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransAllTime.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allTimeTrans = action.payload;
      })
      .addCase(getTransAllTime.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = transactionsSlice.actions;
export default transactionsSlice.reducer;
