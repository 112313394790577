import React from "react";
import { Row } from "reactstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import ReplyActions from "./ReplyActions";

interface IProps {
  date: any;
  name: string;
  complain: string;
}

const ReplyChart = ({ date, name, complain }: IProps) => {
  return (
    <Row className="mt-5">
      <div className="flex justify-center flex-col">
        <div className="py-1 self-center px-3 w-fit text-[#1B1C1E80] bg-[#199DE81A] rounded-3xl">
          <p>{date}</p>
        </div>

        <div className="flex flex-col mt-5">
          <div className="flex items-center">
            <img
              className="w-5 h-5 rounded-full"
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
              alt=""
            />

            <p className="text-[#1B1C1E80] font-bold ml-2">{name}</p>
            <p className="text-[#1B1C1E80] font-medium ml-5">{date}</p>
          </div>

          <div className="border p-3 w-2/3 rounded-md ml-4 mt-2 border-[#1B1C1E0D]">
            <p className="font-medium">{complain}</p>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default ReplyChart;
