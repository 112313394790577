import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import transfersService from "./transfersService";
import { RootTransactions } from "../../interfaces/transfersInterface";

interface Itrans {
  page: number;
  pageSize: number;
  startDate: string;
  endDate: string;
  startAmount: number;
  endAmount: number;
}

const initialState: RootTransactions = {
  transactions: {
    data: {
      pagination: {
        currentPage: 0,
        nextPage: 1,
        pageSize: 0,
        pageTotal: 0,
        prevPage: 1,
      },
      total: 0,
      transactions: [],
    },
  },
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// GET TRANSFERS
export const getTransfers = createAsyncThunk(
  "transfers/getAll",
  async (params: Itrans, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        return await transfersService.getAllTransfers({
          token,
          page: params.page,
          pageSize: params.pageSize,
          startDate: params.startDate,
          endDate: params.endDate,
          startAmount: params.startAmount,
          endAmount: params.endAmount,
        });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const transfersSlice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransfers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTransfers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.transactions = action.payload;
      })
      .addCase(getTransfers.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = transfersSlice.actions;
export default transfersSlice.reducer;
