import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import OrdersActions from "./OrdersActions";
import { Counter, CounterFormater } from "../../components/Counter";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getAllOrders,
  getOrderDetails,
} from "../../features/orders/orderSlice";
import { useSearchParams } from "react-router-dom";
import Spinner from "../../components/Spinner";

const Orders = () => {
  const dispatch = useAppDispatch();
  const { orderDetails, orders, isLoading } = useAppSelector(
    (state) => state.orders
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const queryPage = searchParams.get("page");

  const todayOrder = orderDetails.data.today.count;
  const alltimeOrder = orderDetails.data.allTime.count;
  const todaySales = orderDetails.data.today.total;
  const alltimeSales = orderDetails.data.allTime.total;

  const formatMoney = (amount: number) => {
    const formatter = new Intl.NumberFormat("en-NG", {
      style: "decimal",
      minimumFractionDigits: 2,
    });

    return formatter.format(amount);
  };

  // Calculate the pagination range dynamically
  const calculatePaginationRange = () => {
    const total = orders?.data?.pagination?.pageTotal;
    const currentPage = page;
    const range = 8;
    const totalPages = Math.ceil(total / pageSize);
    const middle = Math.ceil(range / 2);
    let start = Math.max(currentPage - middle, 1);
    let end = Math.min(start + range - 1, totalPages);

    if (end - start + 1 < range) {
      start = Math.max(end - range + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  useEffect(() => {
    dispatch(getOrderDetails(10));
    dispatch(getAllOrders({ page, pageSize }));
  }, [page, pageSize]);

  useEffect(() => {
    if (queryPage === null) {
      setSearchParams({ page: page.toString() });
    } else {
      setPage(parseInt(queryPage));
    }
    setPageSize(50);
  }, [queryPage, setSearchParams]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <Row className="">
        <Counter title="Orders Today" counter={todayOrder} md={3} />

        <Counter title="Total Orders" counter={alltimeOrder} md={3} />

        <CounterFormater title="Sales Today" counter={todaySales} md={3} />

        <CounterFormater title="Total Sales" counter={alltimeSales} md={3} />
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="border-none">
            <Table hover>
              <thead>
                <tr>
                  <th className="header-cell">Orders</th>
                  <th className="header-cell">Customer</th>
                  <th className="header-cell">Product</th>
                  <th className="header-cell">Qty</th>
                  <th className="header-cell">Price</th>
                  <th className="header-cell">Date</th>
                  <th className="header-cell">Status</th>
                  {/* <th className="header-cell">
                    <MdOutlineKeyboardArrowDown />
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {orders.data.orders.length <= 0 ? (
                  <p>NO ORDERS AVAILABLE</p>
                ) : (
                  orders.data.orders.map((order) => (
                    <tr className="" key={order?.id}>
                      <td className="table-body-data" scope="row">
                        {order?.reference}
                      </td>
                      <td className="table-body-data">
                        <div className="table-data-image">
                          <img
                            className="table-image"
                            src={
                              order?.customer?.avatar ||
                              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                            }
                            alt={order?.customer?.fullname}
                          />{" "}
                          {order?.customer?.fullname}
                        </div>
                      </td>
                      <td className="table-body-data">
                        {order?.productDetails?.name}
                      </td>
                      <td className="table-body-data">
                        {order?.productDetails?.quantity}
                      </td>
                      <td className="table-body-data">
                        &#x20A6; {formatMoney(order?.productDetails?.price)}
                      </td>
                      <td className="table-body-data">
                        {" "}
                        {new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }).format(new Date(order?.createdAt))}
                      </td>
                      <td className="table-body-data">
                        <div
                          className={
                            order?.status === "DELIVERED" ||
                            order?.status === "PICKED_UP" ||
                            order?.status === "COMPLETED" ||
                            order?.status === "APPROVED" ||
                            order?.status === "PAID"
                              ? "status-pill-approved"
                              : order?.status === "IN-PROGRESS" ||
                                order?.status === "AWAITING_DISPATCH" ||
                                order?.status === "TRANSIT" ||
                                order?.status === "AWAITING_PAYMENT" ||
                                order?.status === "REQUESTED"
                              ? "status-pill-processing"
                              : order?.status === "CANCELLED" ||
                                order?.status === "REJECTED"
                              ? "status-pill-suspended"
                              : ""
                          }
                        >
                          {order?.status}
                        </div>
                      </td>
                      {/* <td className="table-body-data">
                        <OrdersActions onDelete={""} onEdit={""} />
                      </td> */}
                    </tr>
                  ))
                )}
                {/* 
                <tr className="">
                  <td className="table-body-data" scope="row">
                    #20005674
                  </td>
                  <td className="table-body-data">
                    <div className="table-data-image">
                      <img
                        className="table-image"
                        src="https://static1.thegamerimages.com/wordpress/wp-content/uploads/2021/07/God-Of-War-2018---Image-Of-Kratos-From-The-Cover-Art.jpg"
                        alt="itodo simon"
                      />{" "}
                      #20005674
                    </div>
                  </td>
                  <td className="table-body-data">Fancy Lady Watch</td>
                  <td className="table-body-data">2</td>
                  <td className="table-body-data">NGN 17,000</td>
                  <td className="table-body-data">Jun 1, 2023</td>
                  <td className="table-body-data">
                    <div className="status-pill-suspended">Cancelled</div>
                  </td>
                  <td className="table-body-data">
                    <OrdersActions onDelete={""} onEdit={""} />
                  </td>
                </tr> */}

                {/* <tr className="">
                  <td className="table-body-data" scope="row">
                    #00456379
                  </td>
                  <td className="table-body-data">
                    <div className="table-data-image">
                      <img
                        className="table-image"
                        src="https://static1.thegamerimages.com/wordpress/wp-content/uploads/2021/07/God-Of-War-2018---Image-Of-Kratos-From-The-Cover-Art.jpg"
                        alt="itodo simon"
                      />{" "}
                      Musti Danjuma
                    </div>
                  </td>
                  <td className="table-body-data">Fancy Lady Handbag</td>
                  <td className="table-body-data">1</td>
                  <td className="table-body-data">NGN 30,000</td>
                  <td className="table-body-data">Jun 1, 2023</td>
                  <td className="table-body-data">
                    <div className="status-pill-processing">Processing</div>
                  </td>
                  <td className="table-body-data">
                    <OrdersActions onDelete={""} onEdit={""} />
                  </td>
                </tr> */}
              </tbody>
            </Table>

            <CardBody className="flex justify-between items-center">
              <select
                name=""
                id=""
                className="w-28 outline-none h-9 border border-[1B1C1E0D] rounded text-[#1B1C1E80]"
                onChange={(e) => setPageSize(parseInt(e.target.value))}
                value={pageSize}
              >
                <option value="10">show 10</option>
                <option value="20">show 20</option>
                <option value="30">show 30</option>
                <option value="40">show 40</option>
                <option value="50">show 50</option>
              </select>
              <Pagination
                aria-label="Page navigation example"
                className="custom-pagination"
                size="sm"
              >
                <PaginationItem className="mr-1" disabled={page === 1}>
                  <PaginationLink
                    href="#"
                    previous
                    onClick={() => setPage(page - 1)}
                  />
                </PaginationItem>

                {calculatePaginationRange().map((pageNum) => (
                  <PaginationItem
                    key={pageNum}
                    className="mr-1"
                    active={pageNum === page}
                  >
                    <PaginationLink href="#" onClick={() => setPage(pageNum)}>
                      {pageNum}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                {orders?.data?.pagination?.pageTotal > 8 && (
                  <PaginationItem>
                    <PaginationLink href="#" onClick={() => setPage(page + 8)}>
                      ...
                    </PaginationLink>
                  </PaginationItem>
                )}

                <PaginationItem
                  className="mr-1"
                  disabled={page === orders?.data?.pagination?.pageTotal}
                >
                  <PaginationLink
                    href="#"
                    next
                    onClick={() => setPage(page + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Orders;
