import axios from "axios";

const API_URL = "https://pxn-api-zzpou.ondigitalocean.app/api/v2/";
// const API_URL = "https://pxn-backend.herokuapp.com/api/v2/";

interface IBroadcast {
  token: string;
  formData: any;
}

const createBroadcast = async ({ token, formData }: IBroadcast) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.post(
      `${API_URL}notification/broadcast/create`,
      formData,
      config
    );

    console.log(response, "from broadcast service");

    return response;
  } catch (error) {
    console.error("Error creating broadcast:", error);
    throw error;
  }
};

const broadcastService = {
  createBroadcast,
};

export default broadcastService;
