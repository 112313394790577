import axios from "axios";

const API_URL = "https://pxn-api-zzpou.ondigitalocean.app/api/v2/";
// const API_URL = "https://pxn-backend.herokuapp.com/api/v2/";

interface IParams {
  token: string;
  page: number;
  pageSize: number;
  startDate?: string;
  endDate?: string;
  startAmount?: number;
  endAmount?: number;
}

const getAllTransfers = async (params: IParams) => {
  const { token, page, pageSize, startDate, endDate, startAmount, endAmount } =
    params;

  console.log({ endDate, startDate }, "hello");

  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    let url = `${API_URL}wallet/transaction/transfers`;

    const queryParams = [];

    if (startDate) {
      queryParams.push(`startDate=${startDate}`);
    }

    if (endDate) {
      queryParams.push(`endDate=${endDate}`);
    }

    if (startAmount) {
      queryParams.push(`startAmount=${startAmount}`);
    }

    if (endAmount) {
      queryParams.push(`endAmount=${endAmount}`);
    }

    // Add pagination parameters with the desired format
    if (page) {
      queryParams.push(`page=${page}`);
    }

    if (pageSize) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    const response = await axios.get(url, config);

    console.log(response, "transfers history");
    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching transfers:", error);
    throw error;
  }
};

const transfersService = {
  getAllTransfers,
};

export default transfersService;
