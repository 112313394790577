import axios from "axios";

// const API_URL = "https://pxn-backend.herokuapp.com/api/v2/";
const API_URL = "https://pxn-api-zzpou.ondigitalocean.app/api/v2/";

interface IParams {
  token: string;
  page: number;
  pageSize: number;
}

// get order details
const getOrdersDetails = async (token: string) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };
  const response = await axios.get(`${API_URL}order/details`, config);

  return response.data;
};

// get all orders
const getAllOrder = async ({ token, page, pageSize }: IParams) => {
  const params = {
    page,
    pageSize,
  };

  const config = {
    headers: {
      "x-access-token": token,
    },
  };
  try {
    const response = await axios.get(API_URL + "order/all", {
      params,
      headers: config.headers,
    });

    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching orders:", error);
    throw error;
  }
};

const orderService = {
  getOrdersDetails,
  getAllOrder,
};

export default orderService;
