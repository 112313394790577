import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { PageTitle } from "../../components/PageTitle";
import HoverCard from "./HoverCard";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getAStoreProds } from "../../features/stores/storesSlice";

interface RowData {
  image: string;
  name: string;
  email: string;
  address: string;
  status: string;
  price: string;
}

const SelectedStore = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { products, isLoading } = useAppSelector((state) => state.stores);
  const [showHoverCard, setShowHoverCard] = useState(false);
  const [hoveredRowData, setHoveredRowData] = useState<RowData | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryPage = searchParams.get("page");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [hoveredItemIndex, setHoveredItemIndex] = useState(-1);

  const handleRowMouseEnter = (item: any) => {
    setHoveredItemIndex(item?.id);
    setHoveredRowData(item);
    setShowHoverCard(true);
  };

  // const handleRowMouseEnter = (item) => {
  //   setHoveredRowData(item);
  //   setShowHoverCard(true);
  // };

  const handleRowMouseLeave = () => {
    setHoveredItemIndex(-1);
  };

  // Calculate the pagination range dynamically
  const calculatePaginationRange = () => {
    const total = products?.data?.pagination?.pageTotal;
    const currentPage = page;
    const range = 8;
    const totalPages = Math.ceil(total / pageSize);
    const middle = Math.ceil(range / 2);
    let start = Math.max(currentPage - middle, 1);
    let end = Math.min(start + range - 1, totalPages);

    if (end - start + 1 < range) {
      start = Math.max(end - range + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  useEffect(() => {
    if (id) {
      const storeId = parseInt(id, 10);

      if (!isNaN(storeId)) {
        dispatch(getAStoreProds({ storeId, page, pageSize }));
      }
    }
  }, [page, pageSize]);

  return (
    <React.Fragment>
      <Row className="">
        <PageTitle title="Welcome to Onigbinde store!" />
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="border-none">
            <Table hover>
              <thead>
                <tr>
                  <th className="header-cell">Product Name</th>
                  <th className="header-cell">Category</th>
                  <th className="header-cell">Price</th>
                  <th className="header-cell">Stock</th>
                  <th className="header-cell">Sold</th>
                </tr>
              </thead>
              <tbody>
                {products?.data?.products?.map((item) => (
                  <tr
                    key={item?.id}
                    className="relative"
                    onMouseEnter={() => handleRowMouseEnter(item)}
                    onMouseLeave={handleRowMouseLeave}
                  >
                    <td className="table-body-data" scope="row">
                      <div className="table-data-image">
                        <img
                          className="w-[30px] h-[30px] rounded-md mr-2"
                          src={
                            item?.productImages[0]?.image ||
                            "https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg"
                          }
                          alt={item?.name}
                        />{" "}
                        {item?.name}
                      </div>
                    </td>
                    <td className="table-body-data">
                      {item?.productCategory?.name}
                    </td>
                    <td className="table-body-data">NGN {item?.price}</td>
                    <td className="table-body-data">{item?.quantity}</td>
                    <td className="table-body-data">
                      {hoveredItemIndex === item?.id && showHoverCard && (
                        <HoverCard rowData={hoveredRowData} />
                      )}
                      {item?.sold}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <CardBody className="flex justify-between items-center">
              <select
                name=""
                id=""
                className="w-28 outline-none h-9 border border-[1B1C1E0D] rounded text-[#1B1C1E80]"
                onChange={(e) => setPageSize(parseInt(e.target.value))}
                value={pageSize}
              >
                <option value="10">show 10</option>
                <option value="20">show 20</option>
                <option value="30">show 30</option>
                <option value="40">show 40</option>
                <option value="50">show 50</option>
              </select>
              <Pagination
                aria-label="Page navigation example"
                className="custom-pagination"
                size="sm"
              >
                <PaginationItem className="mr-1" disabled={page === 1}>
                  <PaginationLink
                    href="#"
                    previous
                    onClick={() => setPage(page - 1)}
                  />
                </PaginationItem>

                {calculatePaginationRange().map((pageNum) => (
                  <PaginationItem
                    key={pageNum}
                    className="mr-1"
                    active={pageNum === page}
                  >
                    <PaginationLink href="#" onClick={() => setPage(pageNum)}>
                      {pageNum}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                {products?.data?.pagination?.pageTotal > 8 && (
                  <PaginationItem>
                    <PaginationLink href="#" onClick={() => setPage(page + 8)}>
                      ...
                    </PaginationLink>
                  </PaginationItem>
                )}

                <PaginationItem
                  className="mr-1"
                  disabled={page === products?.data?.pagination?.pageTotal}
                >
                  <PaginationLink
                    href="#"
                    next
                    onClick={() => setPage(page + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SelectedStore;
