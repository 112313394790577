import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import adminService from "./adminService";
import { IAdmin, RootAdminState } from "../../interfaces/adminInterface";

const userJSON = localStorage?.getItem("user");
const user = userJSON !== null ? JSON.parse(userJSON) : null;

const token = user?.data?.token;

const initialState: RootAdminState = {
  admin: {
    data: {
      companies: 0,
      stores: 0,
      wallets: 0,
      females: 0,
      males: 0,
    },
  },
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// GET ADMIN
export const getAdmin = createAsyncThunk(
  "admin/getAll",
  async (_, thunkAPI) => {
    try {
      return await adminService.getAdmin(token);
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.admin = action.payload;
      })
      .addCase(getAdmin.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = adminSlice.actions;
export default adminSlice.reducer;
