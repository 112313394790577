import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getTransactions } from "../../features/transactions/transactionsSlice";

const formatNumber = (value: number) => {
  if (Math.abs(value) >= 1000000) {
    return (value / 1000000).toFixed(2) + "M";
  } else if (Math.abs(value) >= 1000) {
    return (value / 1000).toFixed(1) + "K";
  } else {
    return value.toString();
  }
};

const SalesChart = ({ year }: any) => {
  console.log({ year });

  const dispatch = useAppDispatch();
  const { WalletTransactions } = useAppSelector((state) => state.transactions);
  console.log({ WalletTransactions });

  const month = WalletTransactions?.WalletTransactions?.map(
    (monthData) => monthData.month
  );
  const totalCount = WalletTransactions?.WalletTransactions?.map(
    (monthData) => monthData.totalAmount
  );

  useEffect(() => {
    dispatch(getTransactions(year));
  }, [dispatch, year]);

  const options: ApexOptions = {
    chart: {
      type: "area",
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      animations: {
        enabled: true,
        easing: "linear",
        speed: 1000,
      },
    },
    xaxis: {
      categories: month,
    },
    yaxis: {
      min: 0,
      max: Math.max(...totalCount) + 10000,
      tickAmount: 4,
      labels: {
        formatter: function (value: number) {
          return formatNumber(value);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "Transactions",
      data: totalCount || [], // Use the extracted totalCount values
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default SalesChart;
