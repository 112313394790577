import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import broadcastService from "./broadcastService";

const initialState = {
  broadcast: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// CREATE STORES
export const createBroadcast = createAsyncThunk(
  "broadcast/create",
  async (formData: any, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        return await broadcastService.createBroadcast({token, formData} );
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const broadcastSclice = createSlice({
  name: "broadcast",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBroadcast.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBroadcast.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(createBroadcast.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = broadcastSclice.actions;
export default broadcastSclice.reducer;
