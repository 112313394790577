import axios from "axios";

const API_BASE_URL = "https://pxn-api-zzpou.ondigitalocean.app/api/v2/";
// const API_BASE_URL = "https://pxn-backend.herokuapp.com/api/v2/";

interface IParams {
  token: string;
  year?: number;
}

interface IHistory {
  token: string;
  page: number;
  pageSize: number;
  // year?: number;
  startDate?: string;
  endDate?: string;
  startAmount?: number;
  endAmount?: number;
}

const getTransactionHistory = async (params: IHistory) => {
  const {
    token,
    page,
    pageSize,
    // year,
    startDate,
    endDate,
    startAmount,
    endAmount,
  } = params;

  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    let url = `${API_BASE_URL}wallet/transaction/all`;

    const queryParams = [];

    // if (year) {
    //   queryParams.push(`year=${year}`);
    // }

    if (startDate) {
      queryParams.push(`startDate=${startDate}`);
    }

    if (endDate) {
      queryParams.push(`endDate=${endDate}`);
    }

    if (startAmount) {
      queryParams.push(`startAmount=${startAmount}`);
    }

    if (endAmount) {
      queryParams.push(`endAmount=${endAmount}`);
    }

    // Add pagination parameters with the desired format
    if (page) {
      queryParams.push(`page=${page}`);
    }

    if (pageSize) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    const response = await axios.get(url, config);

    console.log(response, "trans history");
    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching transactions:", error);
    throw error;
  }
};

const getTransbyMonth = async ({ token, year }: IParams) => {
  // Construct query parameters for the API call based on the provided year
  const queryParams = year ? `?year=${year}` : "";

  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(
      API_BASE_URL + "wallet/transaction/monthly" + queryParams,
      {
        headers: config.headers,
      }
    );

    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching transactions by month:", error);
    throw error;
  }
};

const getAllTimeTrans = async (token: string) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(
      API_BASE_URL + "wallet/transaction/year/genus",
      {
        headers: config.headers,
      }
    );

    console.log(response, "all time res");

    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching transactions by month:", error);
    throw error;
  }
};

const transactionsService = {
  getTransactionHistory,
  getTransbyMonth,
  getAllTimeTrans,
};

export default transactionsService;
