import axios from "axios";

const API_URL = "https://pxn-api-zzpou.ondigitalocean.app/api/v2/";
// const API_URL = "https://pxn-backend.herokuapp.com/api/v2/";

interface IParams {
  token: string;
  page: number;
  pageSize: number;
}

interface ITicket {
  token: string;
  id: number;
}

interface IMail {
  token: string;
  id: number;
  data: any;
}

const getTickets = async ({ token, page, pageSize }: IParams) => {
  const params = {
    page,
    pageSize,
  };

  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(API_URL + "ticket/all", {
      params,
      headers: config.headers,
    });

    console.log(response, "response form tickets");

    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching tickets:", error);
    throw error;
  }
};

const getTicket = async ({ token, id }: ITicket) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(API_URL + `ticket/${id}`, config);

    console.log(response, "response form ticket");

    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching ticket:", error);
    throw error;
  }
};

const sendMail = async ({ token, id, data }: IMail) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.post(
      `${API_URL}ticket/mail/${id}`,
      {
        message: data.message,
      },
      config
    );

    console.log(response.data, "response from ticket");

    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error sending mail:", error);
    throw error;
  }
};

const ticketsService = {
  getTickets,
  getTicket,
  sendMail,
};

export default ticketsService;
