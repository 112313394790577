import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import usersService from "./usersService";
import { RootUsersState } from "../../interfaces/usersInterface";

const initialState: RootUsersState = {
  user: {
    data: {
      user: [],
      transaction: [],
      address: [],
    },
  },
  users: {
    data: {
      pagination: {
        currentPage: 0,
        nextPage: 1,
        pageSize: 0,
        pageTotal: 100,
        prevPage: 1,
      },
      total: 0,
      users: [],
    },
  },
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  suspendedUser: null,
  reinstatedUser: null,
};

export const getAllUsers = createAsyncThunk(
  "users/getAll",
  async (params: { page: number; pageSize: number }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { page, pageSize } = params;
        return await usersService.getAllUsers({ token, page, pageSize });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAUser = createAsyncThunk(
  "user/getA",
  async (id: number, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        return await usersService.getAUser({ token, id });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const suspendUser = createAsyncThunk(
  "users/suspend",
  async (id: number, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const response = await usersService.suspendUser({ token, id });
        // Extract the necessary data from the response
        const suspendedUserData = response.data;
        return suspendedUserData;
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reinstateUser = createAsyncThunk(
  "users/reinstate",
  async (id: number, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const response = await usersService.reinstateUser({ token, id });
        // Extract the necessary data from the response
        const reinstatedUserData = response.data;
        return reinstatedUserData;
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = action.payload;
        state.users.data.total = action.payload.data.total;
        state.users.data.pagination = action.payload.data.pagination;
      })
      .addCase(getAllUsers.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(getAUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(suspendUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(suspendUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.suspendedUser = action.payload;
      })
      .addCase(suspendUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(reinstateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reinstateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.reinstatedUser = action.payload;
      })
      .addCase(reinstateUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = usersSlice.actions;
export default usersSlice.reducer;
