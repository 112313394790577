import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";

const SearchBar = ({ onSearch }: any) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleSearchButtonClick = () => {
    onSearch(searchQuery);
  };

  console.log({ searchQuery });

  return (
    <React.Fragment>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search"
          className={`search-bar__input`}
          value={searchQuery}
          onChange={handleSearchInputChange}
        />

        <BiSearch className="text-lg" onClick={handleSearchButtonClick} />
      </div>
    </React.Fragment>
  );
};

export default SearchBar;
