import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ticketsService from "./ticketsService";
import { RootTicketState } from "../../interfaces/ticketsInterface";

const initialState: RootTicketState = {
  tickets: {
    data: {
      pagination: {
        currentPage: 0,
        nextPage: 1,
        pageSize: 0,
        pageTotal: 0,
        prevPage: 1,
      },
      total: 0,
      tickets: [],
    },
  },
  ticket: {
    success: false, // Set to an appropriate default value
    ticket: {
      id: 0,
      type: "",
      status: "",
      resolved: false,
      complaint: "",
      user: 0,
      count: 0,
      category: null,
      dispatch: null,
      createdAt: "",
      updatedAt: "",
      image: "",
      ticketUser: {
        fullname: "",
        firstname: "",
        lastname: ""
      }
    },
  },
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// GET TICKETS
export const getTickets = createAsyncThunk(
  "tickets/getAll",
  async (params: { page: number; pageSize: number }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { page, pageSize } = params;
        return await ticketsService.getTickets({ token, page, pageSize });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET TICKET
export const getTicket = createAsyncThunk(
  "ticket/getATicket",
  async (id: number, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        return await ticketsService.getTicket({ token, id });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// SEND MAIL
export const sendMail = createAsyncThunk(
  "mail/sendMail",
  async (params: { id: number; data: any }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { id, data } = params;
        return await ticketsService.sendMail({ token, id, data });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ticketSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTickets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTickets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tickets = action.payload;
      })
      .addCase(getTickets.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTicket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.ticket = action.payload;
      })
      .addCase(getTicket.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = ticketSlice.actions;
export default ticketSlice.reducer;
