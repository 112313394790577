import axios from "axios";

const API_URL = "https://pxn-api-zzpou.ondigitalocean.app/api/v2/";
// const API_URL = "https://pxn-backend.herokuapp.com/api/v2/";

interface IParams {
  token: string;
  page: number;
  pageSize: number;
}

interface IProdParams {
  storeId: number;
  token: string;
  page: number;
  pageSize: number;
}

interface IBan {
  token: string;
  id: number;
  data: any;
}

interface IActive {
  token: string;
  id: number;
}

const getAllStores = async ({ token, page, pageSize }: IParams) => {
  const params = {
    page,
    pageSize,
  };

  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(API_URL + "store/all", {
      params,
      headers: config.headers,
    });

    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching stores:", error);
    throw error;
  }
};

// get store products
const getStoreProduct = async ({
  token,
  storeId,
  page,
  pageSize,
}: IProdParams) => {
  const params = {
    page,
    pageSize,
  };

  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(
      API_URL + `store/${storeId}/product/all/`,
      {
        params,
        headers: config.headers,
      }
    );

    console.log(response, "getting store prod");

    return response.data;
  } catch (error) {
    console.error("Error getting store product:", error);
    throw error;
  }
};

// deactivate a store
const deactivateStore = async ({ token, id, data }: IBan) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.put(
      API_URL + `store/${id}/deactivate`,
      data,
      config
    );
    console.log(response, "deactivated");
    return response;
  } catch (error) {
    console.error("Error deactivating store:", error);
    throw error;
  }
};

// activate a store
const activateStore = async ({ token, id }: IActive) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.put(
      API_URL + `store/${id}/activate`,
      {},
      {
        headers: config.headers,
      }
    );
    console.log(response, "activated");
    return response;
  } catch (error) {
    console.error("Error activating store:", error);
    throw error;
  }
};

const storesService = {
  getAllStores,
  deactivateStore,
  activateStore,
  getStoreProduct,
};

export default storesService;
