import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { VerticalProgressBar } from "../Charts";
import UsersActions from "../../pages/Users/UsersActions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getAUser } from "../../features/users/usersSlice";

const UserProfileModal = ({ onDelete, text, args, data, userId }: any) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.users);

  // console.log({ data });

  const progressData = [25, 50, 75, 40, 60, 90];

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    // dispatch(getAUser(data));
  }, []);

  return (
    <React.Fragment>
      <UsersActions
        onEdit={toggle}
        onDelete={onDelete}
        userId={userId}
        userState={text}
      />

      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalBody className="flex flex-col">
          <div className="flex items-center justify-end">
            <button onClick={toggle}>&times;</button>
          </div>
          <div className="flex mb-4 items-center justify-center flex-col w-full">
            <img
              className="rounded-full w-28 h-28"
              src="https://static1.thegamerimages.com/wordpress/wp-content/uploads/2021/07/God-Of-War-2018---Image-Of-Kratos-From-The-Cover-Art.jpg"
              alt=""
            />
            <p className="font-bold text-2xl mt-2">Musa Adamu</p>
            <p className="text-sm text-[#1B1C1E80] leading-6">Nigeria</p>
          </div>

          <div className="flex flex-col ">
            <div className="flex mb-3">
              <div className="w-12 h-12 rounded-[5px] bg-[#199DE81A] flex items-center justify-center"></div>

              <div className="ml-3">
                <p className="text-[#1B1C1E80] text-sm">Email</p>
                <p className="font-semibold">shalomadams@gmail.com</p>
              </div>
            </div>

            <div className="flex mb-3">
              <div className="w-12 h-12 rounded-[5px] bg-[#199DE81A] flex items-center justify-center"></div>

              <div className="ml-3">
                <p className="text-[#1B1C1E80] text-sm">Phone</p>
                <p className="font-semibold">+234 8145678987</p>
              </div>
            </div>

            <div className="flex mb-3">
              <div className="w-12 h-12 rounded-[5px] bg-[#199DE81A] flex items-center justify-center"></div>

              <div className="ml-3">
                <p className="text-[#1B1C1E80] text-sm">Location</p>
                <p className="font-semibold">
                  No. 12, British American Junc., Jos, Plateau state.
                </p>
              </div>
            </div>
          </div>

          <div className="self-center w-2/3 mt-8 mb-4">
            <p className="font-semibold text-xl ml-2">Performance</p>

            <VerticalProgressBar data={progressData} />
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UserProfileModal;
