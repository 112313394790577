import React from "react";

interface IFields {
  label: string;
  placeholder: string;
  type: string;
  name: string;
  onChange: any;
}

const InputField: React.FC<IFields> = ({
  label,
  placeholder,
  type,
  name,
  onChange,
}) => {
  return (
    <React.Fragment>
      <div className="input-container">
        <label className="label">{label}</label>
        <div className="input">
          <input
            name={name}
            type={type}
            className="input-field"
            required
            placeholder={placeholder}
            onChange={onChange}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default InputField;
