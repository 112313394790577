import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "../features/authentication/authSlice";
import usersReducer from "../features/users/usersSlice";
import storesReducer from "../features/stores/storesSlice";
import adminReducer from "../features/admin/adminSlice";
import transactionsReducer from "../features/transactions/transactionsSlice";
import orderReducer from "../features/orders/orderSlice";
import productReducer from "../features/products/productSlice";
import logisticsReducer from "../features/logistics/logisticsSlice";
import transfersReducer from "../features/transfers/transfersSlice";
import ticktetsReducers from "../features/tickets/ticketsSlice";
import broadcastReducers from "../features/broadcast/broadcastSlice";
export const store = configureStore({
  reducer: {
    user: authReducer,
    users: usersReducer,
    stores: storesReducer,
    admin: adminReducer,
    transactions: transactionsReducer,
    transfers: transfersReducer,
    orders: orderReducer,
    products: productReducer,
    logistics: logisticsReducer,
    tickets: ticktetsReducers,
    broadcast: broadcastReducers,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
