import axios from "axios";

const API_BASE_URL = "https://pxn-api-zzpou.ondigitalocean.app/api/v2/";
// const API_BASE_URL = "https://pxn-backend.herokuapp.com/api/v2/";

interface IParams {
  token: string;
  page: number;
  pageSize: number;
}

interface ISuspend {
  token: string;
  id: number;
}

// GET ALL USER
const getAllUsers = async ({ token, page, pageSize }: IParams) => {
  const params = {
    page,
    pageSize,
  };

  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(API_BASE_URL + "user-mgt/list", {
      params,
      headers: config.headers,
    });
    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching users:", error);
    throw error;
  }
};

// GET A USER
const getAUser = async ({ token, id }: ISuspend) => {
  console.log({ id });

  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.get(API_BASE_URL + `user-mgt/details/${id}`, {
      headers: config.headers,
    });
    console.log({ response });
    return response.data;
  } catch (error) {
    // Handle any errors here if necessary
    console.error("Error fetching users:", error);
    throw error;
  }
};

// SUSPEND A USER
const suspendUser = async ({ token, id }: ISuspend) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.put(
      API_BASE_URL + `user-mgt/suspend/${id}`,
      {},
      {
        headers: config.headers,
      }
    );

    console.log(response, "suspended");
    return response;
  } catch (error) {
    console.error("Error suspending user:", error);
    throw error;
  }
};

// REINSTATE A USER
const reinstateUser = async ({ token, id }: ISuspend) => {
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  try {
    const response = await axios.put(
      API_BASE_URL + `user-mgt/reinstate/${id}`,
      {},
      {
        headers: config.headers,
      }
    );

    console.log(response, "reinstated");
    return response;
  } catch (error) {
    console.error("Error reinstate user:", error);
    throw error;
  }
};

const usersService = {
  getAllUsers,
  getAUser,
  suspendUser,
  reinstateUser,
};

export default usersService;
