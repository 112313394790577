import React from "react";
import { BiSolidDashboard, BiTransferAlt } from "react-icons/bi";
import {
  FaClipboardCheck,
  FaPaperPlane,
  FaUsers,
  FaBroadcastTower,
} from "react-icons/fa";
import {
  MdOutlineStorefront,
  MdDeliveryDining,
  MdOutlineWallet,
  MdChatBubbleOutline,
} from "react-icons/md";
import { PiNotebookBold } from "react-icons/pi";
import { TbHomeCancel } from "react-icons/tb";
import { NavLink, useLocation } from "react-router-dom";

const navData = [
  {
    name: "Dashboard",
    icon: <BiSolidDashboard />,
    path: "dashboard",
  },
  {
    name: "Users",
    icon: <FaUsers />,
    path: "users",
  },
  {
    name: "Orders",
    icon: <FaClipboardCheck />,
    path: "orders",
  },
  {
    name: "Products",
    icon: <FaPaperPlane />,
    path: "products",
  },
  {
    name: "Stores",
    icon: <MdOutlineStorefront />,
    path: "stores",
  },
  {
    name: "Logistics",
    icon: <MdDeliveryDining />,
    path: "logistics",
  },
  {
    name: "Un-Verified",
    icon: <TbHomeCancel />,
    path: "not-verified",
  },
  {
    name: "Transactions",
    icon: <PiNotebookBold />,
    path: "transactions",
  },
  {
    name: "Transfers",
    icon: <BiTransferAlt />,
    path: "transfers",
  },
  {
    name: "Wallet",
    icon: <MdOutlineWallet />,
    path: "wallet",
  },
  {
    name: "Support",
    icon: <MdChatBubbleOutline />,
    path: "support",
  },
  {
    name: "Broadcast",
    icon: <FaBroadcastTower />,
    path: "broadcast",
  },
];

const SideBar = () => {
  const location = useLocation();

  return (
    <div className="side-bar">
      {navData.map((nav) => {
        const isActive = location.pathname === nav.path;

        return (
          <NavLink to={nav.path} className="side-bar__item" key={nav.path}>
            <div
              className={`${
                isActive ? "side-bar__active-rectangle" : "side-bar__rectangle"
              }`}
            />
            <div className="flex items-center ml-4">
              {nav.icon}
              <p className="ml-4">{nav.name}</p>
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default SideBar;
