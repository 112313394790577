import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import ProductsActions from "./ProductsActions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  banProduct,
  getAllProducts,
  permitProduct,
} from "../../features/products/productSlice";
import Spinner from "../../components/Spinner";
import { useSearchParams } from "react-router-dom";

const initialState = { reason: "" };

const Products = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryPage = searchParams.get("page");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [isBanModalOpen, setIsBanModalOpen] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [storeId, setStoreId] = useState<number>(0);
  const [productId, setProductId] = useState<number>(0);

  const { reason } = formData;

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const { products, isLoading } = useAppSelector((state) => state.products);

  const formatMoney = (amount: number) => {
    const formatter = new Intl.NumberFormat("en-NG", {
      style: "decimal",
      minimumFractionDigits: 2,
    });

    return formatter.format(amount);
  };

  // Calculate the pagination range dynamically
  const calculatePaginationRange = () => {
    const total = products?.data?.pagination?.pageTotal;
    const currentPage = page;
    const range = 8;
    const totalPages = Math.ceil(total / pageSize);
    const middle = Math.ceil(range / 2);
    let start = Math.max(currentPage - middle, 1);
    let end = Math.min(start + range - 1, totalPages);

    if (end - start + 1 < range) {
      start = Math.max(end - range + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const handleProductAction = async (
    storeId: number,
    id: number,
    actionType: any
  ) => {
    if (actionType === null || actionType === false) {
      // Open the ban modal
      setIsBanModalOpen(true);

      // Set the storeId and productId in state
      setStoreId(storeId);
      setProductId(id);
    } else if (actionType === true) {
      await dispatch(permitProduct({ storeId, id }));
      // Fetch the updated user list immediately
      await dispatch(getAllProducts({ page, pageSize }));
    }
  };

  // Function to handle ban submission
  const handleBanSubmission = async () => {
    const data = {
      reason: formData.reason,
    };

    // Dispatch the banProduct action with ban reason, storeId, and productId
    await dispatch(
      banProduct({
        storeId,
        id: productId,
        data,
      })
    );

    // Close the ban modal
    setIsBanModalOpen(false);

    // Fetch the updated product list
    await dispatch(getAllProducts({ page, pageSize }));
  };

  useEffect(() => {
    dispatch(getAllProducts({ page, pageSize }));
  }, [page, pageSize]);

  useEffect(() => {
    if (queryPage === null) {
      setSearchParams({ page: page.toString() });
    } else {
      setPage(parseInt(queryPage));
    }
    setPageSize(50);
  }, [queryPage, setSearchParams]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <React.Fragment>
      <Row className="">
        <Col>
          <Card className="border-none">
            <Table hover>
              <thead>
                <tr>
                  <th className="header-cell">Product Name</th>
                  <th className="header-cell">Category</th>
                  <th className="header-cell">Price</th>
                  <th className="header-cell">Store</th>
                  <th className="header-cell">Stock</th>
                  <th className="header-cell">Sold</th>
                  <th className="header-cell">Actions</th>
                </tr>
              </thead>
              <tbody>
                {products.data.products.map((item) => (
                  <tr
                    className={`${item?.banned && "disabled"}`}
                    key={item?.id}
                  >
                    <td className="table-body-data" scope="row">
                      <div className="table-data-image">
                        <img
                          className="table-image"
                          src={
                            item?.productImages[0]?.image ||
                            "https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg"
                          }
                          alt={item?.name}
                        />{" "}
                        {item?.name}
                      </div>
                    </td>
                    <td className="table-body-data">
                      {item?.productCategory?.name}
                    </td>
                    <td className="table-body-data">
                      &#x20A6;{formatMoney(item?.price)}
                    </td>
                    <td className="table-body-data">{item?.vendor?.name}</td>
                    <td className="table-body-data">{item?.quantity}</td>
                    <td className="table-body-data">
                      {!item?.sold ? "0" : item?.sold}
                    </td>
                    <td className="table-body-data">
                      <ProductsActions
                        text={!item?.banned ? "Ban Product" : "Permit Product"}
                        onBan={() =>
                          handleProductAction(
                            item?.vendor?.id,
                            item?.id,
                            item?.banned
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <CardBody className="flex justify-between items-center">
              <select
                name=""
                id=""
                className="w-28 outline-none h-9 border border-[1B1C1E0D] rounded text-[#1B1C1E80]"
                onChange={(e) => setPageSize(parseInt(e.target.value))}
                value={pageSize}
              >
                <option value="10">show 10</option>
                <option value="20">show 20</option>
                <option value="30">show 30</option>
                <option value="40">show 40</option>
                <option value="50">show 50</option>
              </select>
              <Pagination
                aria-label="Page navigation example"
                className="custom-pagination"
                size="sm"
              >
                <PaginationItem className="mr-1" disabled={page === 1}>
                  <PaginationLink
                    href="#"
                    previous
                    onClick={() => setPage(page - 1)}
                  />
                </PaginationItem>

                {calculatePaginationRange().map((pageNum) => (
                  <PaginationItem
                    key={pageNum}
                    className="mr-1"
                    active={pageNum === page}
                  >
                    <PaginationLink href="#" onClick={() => setPage(pageNum)}>
                      {pageNum}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                {products?.data?.pagination?.pageTotal > 8 && (
                  <PaginationItem>
                    <PaginationLink href="#" onClick={() => setPage(page + 8)}>
                      ...
                    </PaginationLink>
                  </PaginationItem>
                )}

                <PaginationItem
                  className="mr-1"
                  disabled={page === products?.data?.pagination?.pageTotal}
                >
                  <PaginationLink
                    href="#"
                    next
                    onClick={() => setPage(page + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Ban Modal */}
      <Modal
        isOpen={isBanModalOpen}
        toggle={() => setIsBanModalOpen(!isBanModalOpen)}
      >
        <ModalHeader toggle={() => setIsBanModalOpen(!isBanModalOpen)}>
          Ban Product
        </ModalHeader>
        <ModalBody>
          <p>Enter the reason for banning the product:</p>
          <Input type="text" name="reason" value={reason} onChange={onChange} />
        </ModalBody>
        <ModalFooter>
          <Button className="bg-danger" onClick={handleBanSubmission}>
            Ban Product
          </Button>{" "}
          <Button
            className="bg-secondary"
            onClick={() => setIsBanModalOpen(!isBanModalOpen)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Products;
