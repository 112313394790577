import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import logisticsService from "./logisticsService";
import { RootLogistics } from "../../interfaces/logisticsInterface";

const initialState: RootLogistics = {
  unVerified: {
    data: [],
  },
  logistics: {
    data: {
      pagination: {
        currentPage: 0,
        nextPage: 1,
        pageSize: 0,
        pageTotal: 0,
        prevPage: 1,
      },

      companies: [],
    },
  },

  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  deactivate: null,
  activate: null,
};

// GET ALL RIDERS
export const getCompanyRiders = createAsyncThunk(
  "company/riders",
  async (id: number, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;
      if (token) {
        return await logisticsService.getCompanyRiders({
          token,
          id,
        });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET ALL PRODUCTS
export const getAllLogistics = createAsyncThunk(
  "allLogistics/getAll",
  async (params: { page: number; pageSize: number }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;
      if (token) {
        const { page, pageSize } = params;
        return await logisticsService.getAllLogistics({
          token,
          page,
          pageSize,
        });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET ALL UNVERIFIED
export const getUnverified = createAsyncThunk(
  "unverified/getAll",
  async (_, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;
      if (token) {
        return await logisticsService.getUnverified(token);
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deactivateCompany = createAsyncThunk(
  "company/deactivate",
  async (params: { id: number; data: any }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { id, data } = params;
        const response = await logisticsService.deactivateCompany({
          token,
          id,
          data,
        });
        // Extract the necessary data from the response
        const deactivatedCompany = response.data;
        return deactivatedCompany;
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const activateCompany = createAsyncThunk(
  "company/activate",
  async (params: { id: number }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { id } = params;
        const response = await logisticsService.activateCompany({
          token,
          id,
        });
        // Extract the necessary data from the response
        const activatedCompany = response.data;
        return activatedCompany;
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyCompany = createAsyncThunk(
  "company/verify",
  async (id: number, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const response = await logisticsService.verifyCompany({
          token,
          id,
        });
        // Extract the necessary data from the response
        const activatedCompany = response.data;
        return activatedCompany;
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logisticsSlice = createSlice({
  name: "logistics",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLogistics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllLogistics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.logistics = action.payload;
      })
      .addCase(getAllLogistics.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getCompanyRiders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanyRiders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.logistics = {
          data: {
            pagination: state.logistics.data.pagination,
            companies: [action.payload.data],
          },
        };
      })
      .addCase(getCompanyRiders.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deactivateCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deactivateCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deactivate = action.payload;
      })
      .addCase(deactivateCompany.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(activateCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activateCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.activate = action.payload;
      })
      .addCase(activateCompany.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getUnverified.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnverified.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.unVerified = action.payload;
      })
      .addCase(getUnverified.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(verifyCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.logistics = action.payload;
      })
      .addCase(verifyCompany.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = logisticsSlice.actions;
export default logisticsSlice.reducer;
