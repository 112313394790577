import React, { useState } from "react";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { createBroadcast } from "../../features/broadcast/broadcastSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const Broadcast = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.broadcast);

  const [formData, setFormData] = useState({
    title: "",
    body: "",
    isDraft: false,
  });

  const handleInputChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(createBroadcast(formData));
  };

  return (
    <React.Fragment>
      <Row className="">
        <Col>
          <Card className="border-none">
            <CardBody>
              <form className="flex flex-col" onSubmit={handleSubmit}>
                <input
                  type="text"
                  onChange={handleInputChange}
                  className="outline-none h-12 border border-[#199DE8] p-2"
                  name="title"
                  placeholder="Enter Title for broadcast"
                  required
                />
                <textarea
                  className="outline-none border border-[#199DE8] mt-2 p-2"
                  name="body"
                  placeholder="Enter Broadcast"
                  id=""
                  cols={30}
                  rows={10}
                  required
                  onChange={handleInputChange}
                ></textarea>

                <label className="mt-2">
                  <input
                    type="radio"
                    name="isUrgent"
                    checked={formData.isDraft}
                    onChange={handleInputChange}
                  />{" "}
                  Draft
                </label>

                <button
                  type="submit"
                  className="text-white bg-[#199DE8] hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none mt-2"
                >
                  {isLoading && (
                    <Spinner color="light" className="mr-2" size="sm">
                      Loading...
                    </Spinner>
                  )}
                  Broadcast
                </button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Broadcast;
