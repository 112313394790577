import React, { useState, useRef, useEffect } from "react";
import { SlOptionsVertical } from "react-icons/sl";

const ProductsActions = ({ onBan, text }: any) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setShowDropDown(!showDropDown);
  };

  const closeDropdown = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropDown(false);
    }
  };

  useEffect(() => {
    if (showDropDown) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [showDropDown]);

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <div
        onClick={toggleDropdown}
        className="w-fit relative hover:shadow-md p-2 flex items-center justify-center hover:p-1 cursor-pointer hover:bg-white hover:rounded-full transition-all duration-500"
      >
        <SlOptionsVertical className="text-xs cursor-pointer" />
      </div>
      {showDropDown && (
        <div className="absolute top-0 right-5 w-40 rounded-md flex flex-col action-shadow bg-white">
          <div
            onClick={onBan}
            className=" cursor-pointer w-full hover:bg-red-200 p-1 flex items-center justify-center text-sm rounded-md"
          >
            <div className="w-full text-center font-semibold">
              <p>{text}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsActions;
