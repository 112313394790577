import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getUnverified,
  verifyCompany,
} from "../../features/logistics/logisticsSlice";
import { format } from "date-fns";
import Spinner from "../../components/Spinner";

const Unverified = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { unVerified, isLoading } = useAppSelector((state) => state.logistics);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");

  const formatMoney = (amount: any) => {
    const formatter = new Intl.NumberFormat("en-NG", {
      style: "decimal",
      minimumFractionDigits: 2,
    });

    return formatter.format(amount);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const verify = (id: number, name: string) => {
    setSelectedCompanyId(id);
    setSelectedCompanyName(name);
    toggleModal();
  };
  const handleVerification = () => {
    dispatch(verifyCompany(selectedCompanyId));
    toggleModal();
  };

  useEffect(() => {
    dispatch(getUnverified());
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <Row className="mt-4">
        <Col>
          <Card className="border-none">
            <Table hover>
              <thead>
                <tr>
                  <th className="header-cell">Company Name</th>
                  <th className="header-cell">Name</th>
                  <th className="header-cell">Email</th>
                  <th className="header-cell">Phone</th>
                  <th className="header-cell">Adress</th>
                  <th className="header-cell">City</th>
                  <th className="header-cell">State</th>
                  <th className="header-cell">Actions</th>
                </tr>
              </thead>
              <tbody>
                {unVerified?.data?.map((item) => (
                  <tr key={item?.id}>
                    <td className="table-body-data" scope="row">
                      {item?.name}
                    </td>
                    <td className="table-body-data">
                      {item?.manager?.fullname}
                    </td>
                    <td className="table-body-data">{item?.email}</td>
                    <td className="table-body-data">{item?.phone}</td>
                    <td className="table-body-data">{item?.address}</td>
                    <td className="table-body-data">{item?.city}</td>
                    <td className="table-body-data">{item?.state}</td>
                    <td className="table-body-data">
                      <Button
                        className="bg-success"
                        size="sm"
                        onClick={() => verify(item.id, item.name)}
                      >
                        Verify
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      {/* Modal for verification confirmation */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Verify Company</ModalHeader>
        <ModalBody>
          Are you sure you want to verify the company{" "}
          <strong>{selectedCompanyName}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button className="bg-success" onClick={handleVerification}>
            Yes
          </Button>{" "}
          <Button className="bg-danger" onClick={toggleModal}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Unverified;
