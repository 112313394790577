import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderService from "./orderService";

const initialState: RootOrdersState = {
  orders: {
    data: {
      pagination: {
        currentPage: 0,
        nextPage: 1,
        pageSize: 0,
        pageTotal: 100,
        prevPage: 1,
      },
      total: 0,
      orders: [],
    },
  },
  orderDetails: {
    data: {
      allTime: {
        count: 0,
        total: 0,
      },
      today: {
        count: 0,
        total: 0,
      },
    },
  },

  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// GET ORDERS DETAILS
export const getOrderDetails = createAsyncThunk(
  "orders/details",
  async (id: any, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        return await orderService.getOrdersDetails(token);
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET ALL ORDERS
export const getAllOrders = createAsyncThunk(
  "allOrders/getAll",
  async (params: { page: number; pageSize: number }, thunkAPI) => {
    try {
      const userJSON = localStorage?.getItem("user");
      const user = userJSON !== null ? JSON.parse(userJSON) : null;
      const token = user?.data?.token;

      if (token) {
        const { page, pageSize } = params;
        return await orderService.getAllOrder({ token, page, pageSize });
      } else {
        // Handle the case when there is no token available
        throw new Error("Token is missing.");
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.orderDetails = action.payload;
      })
      .addCase(getOrderDetails.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAllOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.orders = action.payload;
      })
      .addCase(getAllOrders.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = orderSlice.actions;
export default orderSlice.reducer;
