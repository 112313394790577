import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

// LAYOUTS
import { RootLayout } from "../layout";

// pages
import { Dashboard } from "../pages/Dashboard";
import { Users } from "../pages/Users";
import { Orders } from "../pages/Orders";
import { Products } from "../pages/Products";
import { Transactions } from "../pages/Transactions";
import { Transfers } from "../pages/Transfers";
import { SelectedStore, Stores } from "../pages/Stores";
import { Logistics, Riders, Unverified } from "../pages/Logistics";
import { Support, SupportReply } from "../pages/Support";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import { Login } from "../pages/Authentication";
import Broadcast from "../pages/Broadcast/Broadcast";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Login />} />

      <Route path="admin" element={<RootLayout />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="users" element={<Users />} />
        <Route path="orders" element={<Orders />} />
        <Route path="products" element={<Products />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="transfers" element={<Transfers />} />
        <Route path="stores" element={<Stores />} />
        <Route path="selected-store/:id" element={<SelectedStore />} />
        <Route path="logistics" element={<Logistics />} />
        <Route path="not-verified" element={<Unverified />} />
        <Route path="logistics/:id" element={<Riders />} />
        <Route path="support" element={<Support />} />
        <Route path="support-reply/:id" element={<SupportReply />} />
        <Route path="broadcast" element={<Broadcast />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Route>
  )
);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
