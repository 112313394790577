import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { PageTitle } from "../../components/PageTitle";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import StoresAction from "./StoresAction";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  activateStore,
  deactivateStore,
  getAllStores,
} from "../../features/stores/storesSlice";
import Spinner from "../../components/Spinner";

interface RowData {
  image: string;
  name: string;
  email: string;
  address: string;
  status: string;
  price: string;
}

const initialState = { reason: "" };
const Stores = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { stores, isLoading } = useAppSelector((state) => state.stores);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryPage = searchParams.get("page");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [formData, setFormData] = useState(initialState);
  const [isBanModalOpen, setIsBanModalOpen] = useState(false);
  const [companyId, setCompanyId] = useState<number>(0);
  const { reason } = formData;

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // Calculate the pagination range dynamically
  const calculatePaginationRange = () => {
    const total = stores?.data?.pagination?.pageTotal;
    const currentPage = page;
    const range = 8;
    const totalPages = Math.ceil(total / pageSize);
    const middle = Math.ceil(range / 2);
    let start = Math.max(currentPage - middle, 1);
    let end = Math.min(start + range - 1, totalPages);

    if (end - start + 1 < range) {
      start = Math.max(end - range + 1, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const handleStoreAction = async (id: number, actionType: any) => {
    if (actionType === true || actionType === null) {
      setIsBanModalOpen(true);

      setCompanyId(id);
    } else if (actionType === false) {
      await dispatch(activateStore({ id }));
      // Fetch the updated user list immediately
      await dispatch(getAllStores({ page, pageSize }));
    }
  };

  // Function to handle ban submission
  const handleBanSubmission = async () => {
    const data = {
      reason: formData.reason,
    };

    await dispatch(
      deactivateStore({
        id: companyId,
        data,
      })
    );

    // Close the ban modal
    setIsBanModalOpen(false);

    // Fetch the updated product list
    await dispatch(getAllStores({ page, pageSize }));
  };

  useEffect(() => {
    dispatch(getAllStores({ page, pageSize }));
  }, [page, pageSize]);

  useEffect(() => {
    if (queryPage === null) {
      setSearchParams({ page: page.toString() });
    } else {
      setPage(parseInt(queryPage));
    }
    setPageSize(50);
  }, [queryPage, setSearchParams]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <React.Fragment>
      <Row className="">
        <PageTitle title="Stores List" />
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="border-none">
            <Table hover>
              <thead>
                <tr>
                  <th className="header-cell">Store Name</th>
                  <th className="header-cell">Description</th>
                  <th className="header-cell">Address</th>
                  <th className="header-cell">Status</th>
                  <th className="header-cell">
                    <MdOutlineKeyboardArrowDown />
                  </th>
                </tr>
              </thead>
              <tbody>
                {stores?.data?.stores?.map((store) => (
                  <tr key={store?.id} className="relative">
                    <td className="table-body-data" scope="row">
                      <div
                        onClick={() =>
                          navigate(`/admin/selected-store/${store.id}`)
                        }
                        className="table-data-image hover:bg-white hover:shadow-lg rounded-lg transition-all duration-500 cursor-pointer"
                      >
                        <img
                          className="w-[30px] h-[30px] rounded-md mr-2"
                          src={
                            store?.logo ||
                            "https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg"
                          }
                          alt="itodo simon"
                        />{" "}
                        {store?.name}
                      </div>
                    </td>
                    <td className="table-body-data">{store?.description}</td>
                    <td className="table-body-data">{store?.address}</td>
                    <td className="table-body-data">
                      <div
                        className={
                          store?.status === true
                            ? "status-pill-approved"
                            : "status-pill-suspended"
                        }
                      >
                        {store?.status === true ? "Active" : "Deactivated"}
                      </div>
                    </td>
                    <td className="table-body-data cursor-pointer">
                      <StoresAction
                        text={
                          store?.status === true ? "Deactivate" : "Activate"
                        }
                        onBan={() =>
                          handleStoreAction(store?.id, store?.status)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <CardBody className="flex justify-between items-center">
              <select
                name=""
                id=""
                className="w-28 outline-none h-9 border border-[1B1C1E0D] rounded text-[#1B1C1E80]"
                onChange={(e) => setPageSize(parseInt(e.target.value))}
                value={pageSize}
              >
                <option value="10">show 10</option>
                <option value="20">show 20</option>
                <option value="30">show 30</option>
                <option value="40">show 40</option>
                <option value="50">show 50</option>
              </select>
              <Pagination
                aria-label="Page navigation example"
                className="custom-pagination"
                size="sm"
              >
                <PaginationItem className="mr-1" disabled={page === 1}>
                  <PaginationLink
                    href="#"
                    previous
                    onClick={() => setPage(page - 1)}
                  />
                </PaginationItem>

                {calculatePaginationRange().map((pageNum) => (
                  <PaginationItem
                    key={pageNum}
                    className="mr-1"
                    active={pageNum === page}
                  >
                    <PaginationLink href="#" onClick={() => setPage(pageNum)}>
                      {pageNum}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                {stores?.data?.pagination?.pageTotal > 8 && (
                  <PaginationItem>
                    <PaginationLink href="#" onClick={() => setPage(page + 8)}>
                      ...
                    </PaginationLink>
                  </PaginationItem>
                )}

                <PaginationItem
                  className="mr-1"
                  disabled={page === stores?.data?.pagination?.pageTotal}
                >
                  <PaginationLink
                    href="#"
                    next
                    onClick={() => setPage(page + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* Ban Modal */}
      <Modal
        isOpen={isBanModalOpen}
        toggle={() => setIsBanModalOpen(!isBanModalOpen)}
      >
        <ModalHeader toggle={() => setIsBanModalOpen(!isBanModalOpen)}>
          Ban Store
        </ModalHeader>
        <ModalBody>
          <p>Enter the reason for deactivating the store:</p>
          <Input type="text" name="reason" value={reason} onChange={onChange} />
        </ModalBody>
        <ModalFooter>
          <Button className="bg-danger" onClick={handleBanSubmission}>
            Deactivate Store
          </Button>{" "}
          <Button
            className="bg-secondary"
            onClick={() => setIsBanModalOpen(!isBanModalOpen)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Stores;
