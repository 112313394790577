import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { InputField } from "../../components/InputField";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { login, reset } from "../../features/authentication/authSlice";
import { useNavigate } from "react-router-dom";
import { MdWarning } from "react-icons/md";
import LOGO from "../../assets/PayxnREDO 1.png";

const initialState = { username: "", password: "" };

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState("");
  const { user, isError, message, isSuccess, isLoading } = useAppSelector(
    (state) => state.user
  );

  console.log(error, "..");

  const { username, password } = formData;

  // TRACKS THE INPUT VALUE
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // SUBMITS THE FORM
  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const userData = {
      username,
      password,
    };

    dispatch(login(userData));

    if (isError) {
      setError(message);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/admin/dashboard");
    }

    return () => {
      // Reset the state when the component unmounts
      dispatch(reset(user));
    };
  }, [isSuccess, navigate]);

  return (
    <React.Fragment>
      <Row>
        <div className="h-screen flex justify-center items-center">
          <Card
            className="border-none w-2/5"
            style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
          >
            <CardBody>
              <div className="">
                <div className="w-full flex justify-center items-center">
                  <img src={LOGO} alt="PXN" width={150} height={100} />
                </div>
                <p className="font-semibold text-[#1B1C1E80] mb-2 text-xl text-center">
                  Welcome To <span className="text-[#199DE8]">PXN</span>{" "}
                  Dashboard
                </p>
                <p className="text-[#1B1C1E80] text-xs mb-4 text-center">
                  Login To Continue
                </p>

                <div className="">
                  <form onSubmit={onSubmit}>
                    <InputField
                      label="Email"
                      type="email"
                      name="username"
                      placeholder="Enter your email"
                      onChange={onChange}
                    />
                    <InputField
                      label="Password"
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      onChange={onChange}
                    />

                    {isError && (
                      <div className="flex justify-center items-centers text-red-500 text-xs mb-4">
                        <MdWarning />
                        <p className="font-light ml-2">{error}</p>
                      </div>
                    )}

                    <div className="flex justify-center items-center">
                      <Button
                        color="primary"
                        className="w-full font-semibold"
                        disabled={isLoading && true}
                      >
                        {isLoading && (
                          <Spinner color="light" className="mr-2" size="sm">
                            Loading...
                          </Spinner>
                        )}
                        Login
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default Login;
