import React from "react";
import "./styles.scss";
import { Card, CardBody, Row } from "reactstrap";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <React.Fragment>
      <Card className="border-none">
        <CardBody>
          <Row>
            <section className="page_404">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 ">
                    <div className="col-sm-10 col-sm-offset-1  text-center">
                      <div className="four_zero_four_bg">
                        <h1 className="text-center ">404</h1>
                      </div>

                      <div className="contant_box_404">
                        <h3 className="h2">Looks like you're lost</h3>

                        <p>the page you are looking for is not avaible!</p>

                        <Link to="/" className="link_404 rounded-sm">
                          Go to Home
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PageNotFound;
